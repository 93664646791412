import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ViewShopHomepage from "../ViewShopHomepage/ViewShopHomepage";
import Footer from "../Footer/Footer";
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import { useNavigate } from 'react-router-dom';
import {isMobile} from "react-device-detect";
import {devices} from '../../constants/deviceSizes'

const HeaderContainer = styled.div<{showLogo: boolean, isMobile: boolean}>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: ${props => props.isMobile ? '10vw' : (props.showLogo ? '6vw' : '3vw')};
  background-color: ${props => props.isMobile ? 'rgba(254, 228, 231, 0.8)' : (props.showLogo ? '' : 'rgba(254, 228, 231, 0.8)')};
  transition: ${props => props.isMobile ? '' : 'height 0.5s, background-color 0.5s'};
`

const HeaderItems = styled.div<{showLogo: boolean, isMobile: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${props => props.isMobile ? '0% 5%' : (props.showLogo ? '2% 5%': '0% 5%')};
  height: ${props => props.isMobile ? '10vw' : (props.showLogo ? '6vw' : '3vw')};
  transition: height 0.5s;
`

const SocialMediaImage = styled.img<{isMobile: boolean}>`
  height: ${props => props.isMobile ? '6vw' : '1.6vw'};
  cursor: pointer;
  @media only screen and ${devices.sm} {
    height: ${props => props.isMobile ? '6vw' : '3.2vw'};
  }
`

const LogoImage = styled.img<{isMobile: boolean}>`
  margin-left: -8%;
  max-height: 10vw;
  @media only screen and ${devices.sm} {
    margin-left: -20%;
    max-height: 14vw;
  }
  display: ${props => props.isMobile ? 'none': ''};
`

const AdvertImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: fill;
`

const AdvertImageContainer = styled.div<{isMobile: boolean}>`
  flex-grow: 1;
  width: ${props => props.isMobile ? '100%': '75.7vw'};
  height: ${props => props.isMobile ? '102vw': '47.2vw'};
`

const HomePageContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  justify-content: space-between;
  gap: 0;
`

const HomePageTextContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  width: ${props => props.isMobile ? '100%' : '50%'};
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  background-color: #FEE4E7;
  padding-left: ${props => props.isMobile ? '0' : '8.5%'};
  padding-right: ${props => props.isMobile ? '0' : '8.5%'};
  padding-top: 15%;
  padding-bottom: ${props => props.isMobile ? '15%' : '0'};
  font-family: 'AmstelvarAlpha', sans-serif;
`

const NewSiteText = styled.div<{isMobile: boolean}>`
  color: #846854;
  font-size: ${props => props.isMobile ? '10vw': '5vw'};
  font-weight: 400;
  text-align: center;
  font-family: 'Modist', sans-serif;
  letter-spacing: 0.03em;
`

const ShopText = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '5vw': '1vw'};
  font-family: 'AmstelvarAlpha', sans-serif;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  margin-top: ${props => props.isMobile ? '140vw': '32vw'};
`


const SmallText = styled.div<{isMobile: boolean}>`
  color: #846854;
  font-size: ${props => props.isMobile ? '5vw': '2vw'};
  font-weight: 400;
  text-align: center;
  font-family: 'Modist', sans-serif;
  letter-spacing: 0.03em;
  margin-top: 10%;
  margin-bottom: 10%;
`

const MidTextContainer = styled.div<{isMobile: boolean}>`
  background-color: #FEE4E7;
  position: absolute;
  color: #1C1A1A;
  padding: 1.5vw 1vw;
  top: ${props => props.isMobile ? '52vw': '26vw'};
  left: ${props => props.isMobile ? '22%': '39%'};
  font-size: ${props => props.isMobile ? '3vw': '1.2vw'};
  font-weight: 700;
  border-radius: 4px;
  font-family: 'Modist', sans-serif;
  line-height: 27px;
  letter-spacing: 0.215em;
  text-align: center;
`

const ShopContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column': 'row'};
  justify-content: space-between;
  gap: 0;
`

const ShopImageContainer = styled.div<{isMobile: boolean}>`
  flex-grow: 1;
  height: ${props => props.isMobile ? '156vw': '39vw'};
  width: ${props => props.isMobile ? '100%': '32vw'};
  background-size: ${props => props.isMobile ? '': 'cover'};
  background-repeat: round;
`

const TopBrandsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BrandsHeading = styled.div<{isMobile: boolean}>`
  font-weight: 900;
  font-size: ${props => props.isMobile ? '8.5vw': '2.5vw'};
  margin-top: ${props => props.isMobile ? '22vw': '6vw'};
  margin-bottom: ${props => props.isMobile ? '10vw': '0'};
`;

const BrandsImage = styled.img`
  width: 100%;
  margin-top: 2%;
  margin-bottom: 3%;
`;

const BrandsText = styled.div<{isMobile: boolean}>`
  background-color: #FEE4E7;
  color: #1C1A1A;
  padding: ${props => props.isMobile ? '5vw 6vw': '2vw 6vw'};
  font-size: ${props => props.isMobile ? '4.5vw': '1.4vw'};
  font-weight: 700;
  border-radius: 4px;
  margin-bottom: ${props => props.isMobile ? '15%': '3%'};
  font-family: 'Modist', sans-serif;
  line-height: ${props => props.isMobile ? '2vw': '1.6vw'};
  letter-spacing: 0.215em;
`;

const ProductWithPrizeContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  gap: 3.5%;
  margin: ${props => props.isMobile ? '0' : '2% 4% 4%'};
`;

const ProductImage = styled.img<{isMobile: boolean}>`
  height: ${props => props.isMobile ? '100%' : '100%'};
  width: ${props => props.isMobile ? '100vw' : '100%'};
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductName = styled.div<{isMobile: boolean}>`
  font-family: 'AmstelvarAlpha', sans-serif;;
  font-size: ${props => props.isMobile ? '3vw' : '0.6vw'};
  font-weight: 800;
  line-height: ${props => props.isMobile ? '4vw' : '0.9vw'};
  letter-spacing: 0.1em;
  text-align: center;
`;

const ProductTextContainer = styled.div<{isMobile: boolean}>`
  margin: ${props => props.isMobile ? '7%' : '10% 0 0 0'};
  border: 1px solid black;
  padding: 1vw;
`;

const ViewShopContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column': 'row'};
`

const SharedStoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeartShape = styled.img`
  
`

const SharedStoriesTitle = styled.div<{isMobile: boolean}>`
  font-weight: 900;
  font-size: ${props => props.isMobile ? '5.5vw' : '1.3vw'};
  line-height: ${props => props.isMobile ? '7vw' : '6vw'};
  letter-spacing: 0.2vw;
  color: #4D4D4D;
  padding: ${props => props.isMobile ? '5% 14%' : ''};
  text-align: center;
`

const SharedStory = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  justify-content: space-between;
  margin-left: 6%;
  margin-right: 6%;
  margin-top: ${props => props.isMobile ? '6%' : ''};
  gap: 15vw;
`

const StoryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StoryText = styled.div<{isMobile: boolean}>`
  font-weight: 400;
  font-size: ${props => props.isMobile ? '2.6vw' : '0.7vw'};
  line-height: ${props => props.isMobile ? '4vw' : '1vw'};
  color: #4D4D4D;
  text-align: center;
`

const StoryPersonName = styled.div<{isMobile: boolean}>`
  font-weight: 400;
  font-size: ${props => props.isMobile ? '3.2vw' : '0.8vw'};
  line-height: ${props => props.isMobile ? '4vw' : '1vw'};
  margin-top: 1.6vw;
  color: #4D4D4D;
`

const StoryPersonCity = styled.div<{isMobile: boolean}>`
  font-weight: 600;
  font-size: ${props => props.isMobile ? '3vw' : '0.9vw'};
  line-height: ${props => props.isMobile ? '3vw' : '1vw'};
  margin-top: 0.5vw;
  color: #4D4D4D;
`

const GiftGalleryContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  justify-content: space-between;
  margin-top: 5vw;
`

const GiftRegistry = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${props => props.isMobile ? '15% 10%' : '0 10%'};
`

const GiftRegistryTitle = styled.div<{isMobile: boolean}>`
  position: relative;
  color: #846854;
  font-size: ${props => props.isMobile ? '12vw' : '3vw'};
  letter-spacing: 0.4vw;
  max-width: ${props => props.isMobile ? '47vw' : '10vw'};
`

const GiftRegistryTitleBackground = styled.div<{isMobile: boolean}>`
  position: absolute;
  height: ${props => props.isMobile ? '66vw' : '17vw'};
  width: ${props => props.isMobile ? '38vw' : '10vw'};
  background-color: #FEE4E7;
  z-index: -1000;
  margin-left: ${props => props.isMobile ? '-43vw' : '-12vw'};
  margin-top: ${props => props.isMobile ? '-43vw' : '-10vw'};
`

const GiftRegistrySubTitle = styled.div<{isMobile: boolean}>`
  position: relative;
  color: #281610;
  font-size: ${props => props.isMobile ? '3vw' : '0.7vw'};
  max-width: ${props => props.isMobile ? '50vw' : '10vw'};
  margin-top: ${props => props.isMobile ? '13vw' : '3vw'};
`

const GiftRegistryButton = styled.button<{isMobile: boolean}>`
  color: #281610;
  font-size: ${props => props.isMobile ? '7vw' : '1vw'};
  border: 1px solid #6B5C57;
  padding: 0.5vw 5vw;
  width: 100%;
  margin-top: ${props => props.isMobile ? '10vw' : '3vw'};
  background-color: white;
  cursor: pointer;
`

const ComingSoon = styled.div<{isMobile: boolean}>`
  background-image: ${props => props.isMobile ? 'url("/images/gifts-for-babies-sm.svg")' : 'url("/images/coming-soon.svg")'};
  background-size: ${props => props.isMobile ? 'cover' : 'contain'};
  width: ${props => props.isMobile ? '100%' : '60vw'};
  height: ${props => props.isMobile ? '110vw' : '34.5vw'};
  color: #FFFFFF;
  font-size: 3vw;
  letter-spacing: 1vw;
`

const ContactUsContainer = styled.div<{isMobile: boolean}>`
  position: relative;
  margin-top: ${props => props.isMobile ? '0' : '5vw'};
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
`

const ContactUsImage = styled.img<{isMobile: boolean}>`
  width: ${props => props.isMobile ? '100%' : '50%'};
  height: ${props => props.isMobile ? '100%' : ''};  
`

const TBBRewardButton = styled.button<{isMobile: boolean}>`
  position: absolute;
  top: ${props => props.isMobile ? '10%' : '50%'};
  left: 22%;
  transform: ${props => props.isMobile ? '' : 'translate(-50%, -50%)'};
  -ms-transform: ${props => props.isMobile ? '' : 'translate(-50%, -50%)'};
  color: #281610;
  font-size: ${props => props.isMobile ? '6vw' : '1vw'};
  z-index: 800;
  background-color: rgba(255,255,255, 0);
  border: 1px solid #6B5C57;
  padding: ${props => props.isMobile ? '0.8vw 12vw' : '0.5vw 4vw'};
`

const ContactUsInformation = styled.div<{isMobile: boolean}>`
  position: ${props => props.isMobile ? 'absolute' : ''};
  top: ${props => props.isMobile ? '25%' : ''};
  left: ${props => props.isMobile ? '17%' : '0'};
  display: ${props => props.isMobile ? '' : 'flex'};
  flex-direction: column;
  padding-left: 4vw;
  justify-content: space-between;
`

const ContactUsText = styled.div<{isMobile: boolean}>`
  color: #846854;
  font-size: ${props => props.isMobile ? '10vw' : '2.5vw'};
  font-weight: 400;
  letter-spacing: ${props => props.isMobile ? '1.5vw' : '0.5vw'};
  font-family: initial;
  line-height: ${props => props.isMobile ? "15vw" : "5vw"};
`

const ContactUsDetailContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.isMobile ? '1vw' : '0.4vw'};
`

const ContactUsDetail = styled.div<{isMobile: boolean}>`
  color: #281610;
  font-size: ${props => props.isMobile ? '4vw' : '1vw'};
  letter-spacing: 0.1vw;
`

const BottomDiv = styled.div`
  height: ${isMobile ? '15vw' : '4vw'};
  background-color: #FEE4E7;
`

const Homepage = () => {
  const [isMobileView, setIsMobileView] = useState(false)
  useEffect(() => setIsMobileView(isMobile), [])
  const navigate = useNavigate()

  const [showLogo, setShowLogo] = useState(true)
  const listenToScrollEvent = () => {
    if (window.scrollY < 550) {
      setShowLogo(true)
    } else if (window.scrollY > 550) {
      setShowLogo(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScrollEvent)
    return () => window.removeEventListener('scroll', listenToScrollEvent);
  }, [])

  const redirectToStrollersPage = () => {
    // navigate('/products')
  }

  const handleOnPhoneIconClick = () => {
    navigate('/get-in-touch')
  }

  return (
    <>
      <HeaderContainer showLogo={showLogo} isMobile={isMobileView}>
        <HeaderItems showLogo={showLogo} isMobile={isMobileView}>
          <SocialMediaIcons/>
          {showLogo && <LogoImage loading='lazy' isMobile={isMobileView} src="images/logo.svg"/>}
          <SocialMediaImage onClick={handleOnPhoneIconClick} loading='lazy' isMobile={isMobileView} src="images/contact.svg"/>
        </HeaderItems>
      </HeaderContainer>
      <HomePageContainer isMobile={isMobileView}>
        <AdvertImageContainer isMobile={isMobileView}>
          <AdvertImage loading='lazy' src="images/advertImage.jpeg"/>
          {isMobileView && <MidTextContainer isMobile={isMobileView}>VIEW AMAZON LISTINGS</MidTextContainer>}
        </AdvertImageContainer>
        {!isMobileView && <MidTextContainer isMobile={isMobileView}>VIEW AMAZON LISTINGS</MidTextContainer>}
        <HomePageTextContainer isMobile={isMobileView}>
          <NewSiteText isMobile={isMobileView}>OUR</NewSiteText>
          <NewSiteText isMobile={isMobileView}>NEW SITE</NewSiteText>
          <SmallText isMobile={isMobileView}>IS</SmallText>
          <NewSiteText isMobile={isMobileView}>COMING</NewSiteText>
          <NewSiteText isMobile={isMobileView}>SOON</NewSiteText>
        </HomePageTextContainer>
      </HomePageContainer>
      <ShopContainer isMobile={isMobileView}>
        <ShopImageContainer isMobile={isMobileView} style={{backgroundImage: `url(/images/shopBaby.jpeg)`}}>
          <ShopText isMobile={isMobileView}>SHOP BABY</ShopText>
        </ShopImageContainer>
        <ShopImageContainer isMobile={isMobileView} style={{backgroundImage: `url(images/new-in-banner.gif)`}}>
          <ShopText isMobile={isMobileView} style={{color: "#846854", marginTop: isMobileView ? "10vw" : "4vw"}}>WE HAVE</ShopText>
          <ShopText isMobile={isMobileView} style={{color: "#846854", marginTop: isMobileView ? "122vw" : "27vw"}}>SHOP NEW</ShopText>
        </ShopImageContainer>
        <ShopImageContainer isMobile={isMobileView} style={{backgroundImage: `url(images/shopgirls.svg)`}}>
          <ShopText isMobile={isMobileView}>SHOP GIRLS</ShopText>
        </ShopImageContainer>
        <ShopImageContainer isMobile={isMobileView} style={{backgroundImage: `url(images/shopBoys.svg)`}}>
          <ShopText isMobile={isMobileView}>SHOP BOYS</ShopText>
        </ShopImageContainer>
      </ShopContainer>
      <ShopContainer isMobile={isMobileView}>
        <ShopImageContainer isMobile={isMobileView} style={{backgroundImage: `url(images/shopToys.svg)`}}>
          <ShopText isMobile={isMobileView}>SHOP TOYS</ShopText>
        </ShopImageContainer>
        <ShopImageContainer isMobile={isMobileView} style={{backgroundImage: `url(images/shopClothes.svg)`}}>
          <ShopText isMobile={isMobileView}>SHOP CLOTHES</ShopText>
        </ShopImageContainer>
        <ShopImageContainer isMobile={isMobileView} style={{backgroundImage: `url(images/stroller-babyzen.svg)`}} onClick={redirectToStrollersPage}>
          <ShopText isMobile={isMobileView} style={{color: "#846854", marginTop: isMobileView ? "15vw" : "5vw"}}>STROLLERS</ShopText>
          <ShopText isMobile={isMobileView} style={{color: "#846854", marginTop: isMobileView ? "120vw" : "26vw"}}>SHOP STROLLERS</ShopText>
        </ShopImageContainer>
        <ShopImageContainer isMobile={isMobileView} style={{backgroundImage: `url(images/shopMums.svg)`}}>
          <ShopText isMobile={isMobileView}>SHOP MUMS & DADS</ShopText>
        </ShopImageContainer>
      </ShopContainer>
      <TopBrandsContainer>
        <BrandsHeading isMobile={isMobileView}>Our Top Brands</BrandsHeading>
        <BrandsImage loading='lazy' src={isMobileView ? 'images/brands-small.svg' : 'images/brands.svg'}/>
        <BrandsText isMobile={isMobileView}>OUR + 25 TOP BRANDS</BrandsText>
      </TopBrandsContainer>
      <ViewShopContainer isMobile={isMobileView}>
        <ViewShopHomepage
          image={isMobileView ? '/images/play-time-small.svg' : '/images/playTime.jpeg'}
          title='Play time'
          subTitle='Shop the finest apparel, toys, baby supplies, furnishing for nurseries, and play areas.'
          fontColor={'#281610'}
          titleFontColor={'#846854'}
          backgroundColor={'#ffffffcc'}
          isAttachToBottom={true}
        />
        <ViewShopHomepage
          image={isMobileView ? '/images/gifts-for-babies-small.svg' : '/images/gifts-for-babies.svg'}
          title='Gifts for Babies'
          subTitle="We make it simple to find the ideal baby presents, whether you're looking for small indulgences or something a little more useful."
          fontColor={'#FFFFFF'}
          titleFontColor={'#FFFFFF'}
          backgroundColor={'rgba(168, 124, 95, 0.8)'}
          isAttachToBottom={false}
        />
      </ViewShopContainer>
      <ProductWithPrizeContainer isMobile={isMobileView}>
        <ProductContainer>
          <ProductImage loading='lazy' src='images/BabyZen.svg' isMobile={isMobileView}/>
          <ProductTextContainer isMobile={isMobileView}>
            <ProductName isMobile={isMobileView}>BABYZEN YOYO BASSINET TAUPE</ProductName>
            <ProductName isMobile={isMobileView}>AED 1,379.00</ProductName>
          </ProductTextContainer>
        </ProductContainer>
        <a target='_blank' rel='noopener noreferrer' href='https://www.amazon.ae/gp/product/B07YSP4CDG/ref=ox_sc_act_image_11?smid=A1P14D81KICB60&psc=1'>
          <ProductContainer>
            <ProductImage loading='lazy' src='images/scoot.svg' isMobile={isMobileView}/>
            <ProductTextContainer isMobile={isMobileView}>
              <ProductName isMobile={isMobileView}>SCOOT & RIDE-HIGHWAYKICK 1</ProductName>
              <ProductName isMobile={isMobileView}>AED 495.00</ProductName>
            </ProductTextContainer>
          </ProductContainer>
        </a>
        <a target='_blank' rel='noopener noreferrer' href='https://www.amazon.ae/gp/product/B00BHEEHP2/ref=ox_sc_act_image_3?smid=A1P14D81KICB60&th=1'>
          <ProductContainer>
            <ProductImage loading='lazy' src='images/hapeScoot.svg' isMobile={isMobileView}/>
            <ProductTextContainer isMobile={isMobileView}>
              <ProductName isMobile={isMobileView}>HAPE-SCOOT-AROUND</ProductName>
              <ProductName isMobile={isMobileView}>AED 315.00</ProductName>
            </ProductTextContainer>
          </ProductContainer>
        </a>
        <a target='_blank' rel='noopener noreferrer' href='https://www.amazon.ae/gp/product/B01MG5X0TX/ref=ox_sc_act_image_1?smid=A1P14D81KICB60&psc=1'>
          <ProductContainer>
            <ProductImage loading='lazy' src='images/infant.svg' isMobile={isMobileView}/>
            <ProductTextContainer isMobile={isMobileView}>
              <ProductName isMobile={isMobileView}>INFANT-SLUMBER MELODIES LAMB</ProductName>
              <ProductName isMobile={isMobileView}>AED 164.00</ProductName>
            </ProductTextContainer>
          </ProductContainer>
        </a>
      </ProductWithPrizeContainer>
      <SharedStoriesContainer>
        <HeartShape loading='lazy' src='/images/heart-shape.svg'/>
        <SharedStoriesTitle isMobile={isMobileView}>THE STORIES YOU SHARE WITH US</SharedStoriesTitle>
        <SharedStory isMobile={isMobileView}>
          <StoryTextContainer>
            <StoryText isMobile={isMobileView}>"The merchandise not only arrived in a cute box, but it also did so in just 1 day! I appreciate you being a reliable company that goes the extra mile."</StoryText>
            <StoryPersonName isMobile={isMobileView}>Maria</StoryPersonName>
            <StoryPersonCity isMobile={isMobileView}>United Arab Emirate</StoryPersonCity>
          </StoryTextContainer>
          <StoryTextContainer>
            <StoryText isMobile={isMobileView}>"Your team is outstanding! We are so happy with my son's scooter, especially with the way it was delivered. The additional sweets was also a nice touch."</StoryText>
            <StoryPersonName isMobile={isMobileView}>Leah</StoryPersonName>
            <StoryPersonCity isMobile={isMobileView}>United Arab Emirate</StoryPersonCity>
          </StoryTextContainer>
          <StoryTextContainer>
            <StoryText isMobile={isMobileView}>"Even though I placed my order for Eid late, you kept your word and delivered in time, thus you have my sincere gratitude."</StoryText>
            <StoryPersonName isMobile={isMobileView}>Noor</StoryPersonName>
            <StoryPersonCity isMobile={isMobileView}>United Arab Emirate</StoryPersonCity>
          </StoryTextContainer>
        </SharedStory>
      </SharedStoriesContainer>
      <GiftGalleryContainer isMobile={isMobileView}>
        <GiftRegistry isMobile={isMobileView}>
          <GiftRegistryTitleBackground isMobile={isMobileView}/>
          <GiftRegistryTitle isMobile={isMobileView}>Create your gift registry</GiftRegistryTitle>
          <GiftRegistrySubTitle isMobile={isMobileView}>Design and create your gift registry with the baby boutique and get free delivery within UAE..</GiftRegistrySubTitle>
          <GiftRegistryButton isMobile={isMobileView} onClick={() => navigate('/get-in-touch')}>GIFT REGISTRY</GiftRegistryButton>
        </GiftRegistry>
        <ComingSoon isMobile={isMobileView}/>
      </GiftGalleryContainer>
      <ContactUsContainer isMobile={isMobileView}>
        <TBBRewardButton isMobile={isMobileView} onClick={() => navigate('/get-in-touch')}>TBB Reward</TBBRewardButton>
        <ContactUsImage loading='lazy' isMobile={isMobileView} src={isMobileView ? '/images/contact-us-sm.svg' : '/images/baby-mom-love.svg'}/>
        <ContactUsInformation isMobile={isMobileView}>
          <ContactUsText isMobile={isMobileView}>Contact Us</ContactUsText>
          <ContactUsDetailContainer isMobile={isMobileView}>
            <ContactUsDetail isMobile={isMobileView}>info@thebabyboutique.com</ContactUsDetail>
            <ContactUsDetail isMobile={isMobileView}>+971 4 580 5455</ContactUsDetail>
            <ContactUsDetail isMobile={isMobileView}>+971 585 001 708</ContactUsDetail>
          </ContactUsDetailContainer>
        </ContactUsInformation>
      </ContactUsContainer>
      <Footer/>
      <BottomDiv/>
    </>
  );
}

export default Homepage;
