export const COLORS = {
  lightBrown: '#846854',
  ginger: '#FFA7A7',
  white: '#FFFFFF',
  offWhite: '#CCCCCC',
  black: '#3B3B3B',
  red: '#CB544E',
  beaver: '#A48D73',
  grey: '#959595',
  purpleNavy: '#4B5C78',
  slateGrey: '#6C8790',
  morningBlue: '#94AB91'
}

export const ColorCodeMapping: {[name: string]: string} = {
  'ginger': COLORS.ginger,
  'red': COLORS.red,
  'light-brown': COLORS.lightBrown,
  'beaver': COLORS.beaver,
  'grey': COLORS.grey,
  'black': COLORS.black,
  'purple-navy': COLORS.purpleNavy,
  'slate-grey': COLORS.slateGrey,
  'morning-blue': COLORS.morningBlue
}
