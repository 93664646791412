import React, {useContext} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {CartItemsContext} from "../../App";

const MenuBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #FEEFF1;
  justify-content: space-between;
  width: 86vw;
  padding: 4vw 7vw;
`

const MenuIcon = styled.img`
  width: 7vw;
  cursor: pointer;
`

const CartContainer = styled.div`
  position: relative;
  top: 1vw;
`

const CartItemsCount = styled.div`
  position: absolute;
  top: 2.4vw;
  left: 4vw;
  background-color: #FEE4E7;
  font-size: 2.2vw;
  border-radius: 50%;
  width: 3.2vw;
  height: 3.2vw;
  text-align: center;
  margin: auto;
  line-height: 3.4vw;
`

const MenuBar = () => {
  const cartContext = useContext(CartItemsContext)
  const navigate = useNavigate();
  return <MenuBarContainer>
    <MenuIcon src='/images/home-icon.svg' style={{width: '5vw'}} onClick={() => navigate('/')}/>
    <CartContainer onClick={() => navigate('/cart/1')}>
      <MenuIcon src='/images/cart-icon.svg'/>
      <CartItemsCount>{cartContext?.cartItems.length}</CartItemsCount>
    </CartContainer>
    <MenuIcon src='/images/menu-icon.svg'/>
    <MenuIcon src='/images/likes-icon.svg'/>
    <MenuIcon src='/images/profile-icon.svg'  style={{width: '6vw'}}/>
  </MenuBarContainer>
}

export default MenuBar
