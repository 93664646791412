import React, {ReactNode} from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1% 5%;
`

const HeaderIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5vw;
`

type HeaderProps = {
  leftItems: ReactNode[]
  centerItem: ReactNode
  rightItems: ReactNode[]
}

const Header = ({leftItems, centerItem, rightItems}: HeaderProps) => {
  return <HeaderContainer>
    <HeaderIcons>
      {leftItems}
    </HeaderIcons>
    {centerItem}
    <HeaderIcons>
      {rightItems}
    </HeaderIcons>
  </HeaderContainer>
}

export default Header
