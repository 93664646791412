import React from "react";
import styled from "styled-components";

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2vw;
`

const CategoryText = styled.div`
  color: #1C1A1A;
  font-size: 0.7vw;
  cursor: pointer;
`

type CategoriesProps = {
  categories: string[],
  setTag: (tag: string) => void
}

const Categories = ({categories, setTag}: CategoriesProps) => {
  return <CategoriesContainer>
    {categories.map(category => <CategoryText onClick={() => setTag(category)}>{category}</CategoryText>)}
  </CategoriesContainer>
}

export default Categories
