import React, {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react';
import './App.css';
import "./fonts/AmstelvarAlpha/Nunito-VariableFont_wght.ttf";
import Homepage from "./components/Homepage/Homepage";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ProductsPage from "./components/ProductsPage/ProductsPage";
import GetInTouch from "./components/GetInTouch/GetInTouch";
import ProductDetail from "./components/ProductDetail/ProductDetail";
import {isMobile} from "react-device-detect";
import ProductDetailMobile from "./components/ProductDetail/ProductDetailMobile";
import MenuBar from "./components/MenuBar/MenuBar";
import styled from "styled-components";
import Cart from "./components/Cart/Cart";
import ShippingAddress from "./components/ShippingAddress/ShippingAddress";
import {CartItem, Product} from "./types/types";
import PaymentConfirmation from "./components/PaymentConfirmation/PaymentConfirmation";

const MenuBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
`

export type CartContext = {
  cartItems: CartItem[];
  setCartItems: Dispatch<SetStateAction<CartItem[]>>;
};

export const CartItemsContext = createContext<CartContext | undefined>(undefined);
export const ProductsContext = createContext<Product[] | undefined>(undefined);

function App() {
  const [isMobileDisplay, setIsMobileDisplay] = useState(isMobile)
  const [cartItems, setCartItems] = useState<CartItem[]>(() => {
    const cartItemsInStorage: CartItem[] = JSON.parse(sessionStorage.getItem('cartItems') || '[]')
    return cartItemsInStorage
  });
  const [products, setProducts] = useState<Product[]>(() => {
    const productsInSession: Product[] = JSON.parse(sessionStorage.getItem('products') || '[]')
    return productsInSession
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URI}/products`, {method: 'GET'})
      .then(res => res.json())
      .then(data => setProducts(data.products))
  }, []);

  useEffect(() => {
    sessionStorage.setItem('cartItems', JSON.stringify(cartItems))
  }, [cartItems]);

  useEffect(() => {
    sessionStorage.setItem('products', JSON.stringify(products))
  }, [products]);


  useEffect(() => {
    setIsMobileDisplay(isMobile)
  }, []);
  return <ProductsContext.Provider value={products}>
    <CartItemsContext.Provider value={{cartItems, setCartItems}}>
      <Router>
        <Routes>
          <Route path='/' Component={Homepage}/>
          <Route path='/get-in-touch' Component={GetInTouch}/>
          <Route path='/products' Component={ProductsPage}/>
          <Route path='/products/:id' Component={isMobileDisplay ? ProductDetailMobile : ProductDetail}/>
          <Route path='/cart/:id' Component={Cart}/>
          <Route path='/shipping-address/:id' Component={ShippingAddress}/>
          <Route path='/payment-success' Component={PaymentConfirmation}/>
        </Routes>
        {isMobileDisplay && <MenuBarContainer>
          <MenuBar/>
        </MenuBarContainer>}
      </Router>
    </CartItemsContext.Provider>
  </ProductsContext.Provider>
}

export default App;
