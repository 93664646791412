import React from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import {isMobile} from "react-device-detect";

const Container = styled.div<{isMobile: boolean}>`
  width: 100%;
  height: ${props => props.isMobile ? '78.5vw' : '27vw' };
  position: relative;
`

const ContainerImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

type InnerControllerProps = {
  isAttachToBottom?: boolean
  backgroundColor: string
  isMobile: boolean
}

const InnerContainer = styled.div<InnerControllerProps>`
  position: absolute;
  background-color: ${props => props.backgroundColor};
  width: fit-content;
  max-width: ${props => props.isMobile ? '40vw' : '13vw'};
  min-height: ${props => props.isMobile ? '40vw' : '13vw'};
  left: ${props => props.isMobile ? '10vw' : '5vw'};
  top: ${props => props.isAttachToBottom ? (props.isMobile ? '15vw' : '9vw') : (props.isMobile ? '0' : '0vw')};
  padding: ${props => props.isAttachToBottom ? (props.isMobile ? '10% 3%' : '5% 3%') : (props.isMobile ? '4% 3%' : '5% 3%')};
  display: flex;
  flex-direction: column;
  z-index: 800;
`

const Title = styled.div<{titleFontColor?: string, isMobile: boolean}>`
  color: ${props => props.titleFontColor};
  font-size: ${props => props.isMobile ? '6vw' : '2.5vw'};
  font-weight: 400;
  line-height: ${props => props.isMobile ? '7vw' : '3vw'}; 
  letter-spacing: ${props => props.isMobile ? '1vw' : '0.2vw'};
`

const SubTitle = styled.div<{fontColor?: string, isMobile: boolean}>`
  color: ${props => props.fontColor};
  font-size: ${props => props.isMobile ? '1.9vw' : '0.6vw'};
  font-weight: 400;
  line-height: ${props => props.isMobile ? '2.7vw' : '0.8vw'};
  letter-spacing: 0.1vw;
  margin: ${props => props.isMobile ? '5vw 3vw' : '1.2vw 1.1vw'};
  
  
`

const ViewShowButton = styled.button<{fontColor: string, isMobile: boolean}>`
  color: ${props => props.fontColor};
  border: 0.56px solid #6B5C57;
  padding: ${props => props.isMobile ? '1.5vw 0.3vw' : '1vw 0.3vw'};
  font-size: ${props => props.isMobile ? '2.6vw' : '0.6vw'};
  margin: ${props => props.isMobile ? '6vw 2vw' : '0 1.1vw'};
  font-weight: ${props => props.isMobile ? '400' : '600'};
  letter-spacing: ${props => props.isMobile ? '1vw' : '0.3vw'};
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
`

interface ViewShopHomepageProps {
  image: string;
  title: string;
  subTitle: string;
  backgroundColor: string;
  fontColor: string;
  titleFontColor: string;
  isAttachToBottom?: boolean
}

const ViewShopHomepage = ({image, title, subTitle, titleFontColor, fontColor, backgroundColor, isAttachToBottom}: ViewShopHomepageProps) => {

  const navigate = useNavigate()

  return <Container isMobile={isMobile}>
    <ContainerImage loading='lazy' src={image}/>
    <InnerContainer backgroundColor={backgroundColor} isAttachToBottom={isAttachToBottom} isMobile={isMobile}>
      <Title titleFontColor={titleFontColor} isMobile={isMobile}>{title}</Title>
      <SubTitle fontColor={fontColor} isMobile={isMobile}>{subTitle}</SubTitle>
      <ViewShowButton onClick={() => navigate('/get-in-touch')} fontColor={fontColor} isMobile={isMobile}>VIEW SHOP</ViewShowButton>
    </InnerContainer>
  </Container>
}

export default ViewShopHomepage
