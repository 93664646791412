import React, {useContext, useEffect} from "react";
import styled from "styled-components";
import {LogoImage} from "../ProductsPage/ProductsPage";
import {COLORS} from "../../constants/colors";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import {CartItemsContext} from "../../App";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(#FFFFFF, #FEE4E7);
  height: 100vh;
`

const PaymentMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${isMobile ? '30vw' : '10vw'};
`
const MessageTitle = styled.div`
  font-size: ${isMobile ? '7vw' : '2vw'};
  font-weight: 700;
  color: ${COLORS.lightBrown};
  font-family: Amethysta,"Al Bayan";
`

const MessageSubtitle = styled.div`
  font-size: ${isMobile ? '4vw' : '1vw'};
  font-weight: 600;
  color: ${COLORS.lightBrown};
  text-align: center;
`

const MailMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${isMobile ? '30vw' : '10vw'};
  gap: ${isMobile ? '7vw' : '2vw'};
`
const MailMessageTitle = styled.div`
  font-size: ${isMobile ? '3.5vw' : '1vw'};
  font-weight: 400;
  color: ${COLORS.lightBrown};
  text-align: center;
`

const MailMessageSubtitle = styled.div`
  font-size: ${isMobile ? '4vw' : '1vw'};
  font-weight: 700;
  color: ${COLORS.lightBrown};
  text-align: center;
  cursor: pointer;
`

const PaymentConfirmation = () => {
  const navigate = useNavigate();
  const cartContext = useContext(CartItemsContext);

  useEffect(() => {
    cartContext?.setCartItems([])
  });

  return <Container>
    <LogoImage src='/images/logo-small.svg' style={{mixBlendMode: 'multiply', height: isMobile ? '20vw' : '5vw'}}/>
    <PaymentMessageBox>
      <MessageTitle>PAYMENT RECEIVED</MessageTitle>
      <MessageSubtitle>THANK YOU</MessageSubtitle>
    </PaymentMessageBox>
    <MailMessageBox>
      <MailMessageTitle>An email will be sent to you with your oder number.</MailMessageTitle>
      <MailMessageSubtitle onClick={() => navigate('/products', {replace: true})}>RETURN TO STORE</MailMessageSubtitle>
    </MailMessageBox>
  </Container>
}

export default PaymentConfirmation