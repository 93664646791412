import {CartItem, Product} from "../types/types";

export const convertToCartItem = (selectedProduct: Product, selectedColor: string, selectedFrame: string): CartItem => {
  return {
    id: selectedProduct._id,
    title: selectedProduct.title,
    title2: selectedProduct.title2,
    brand: selectedProduct.brand,
    price: selectedProduct.price,
    imagePath: selectedProduct.imagePath,
    detail: selectedProduct.detail,
    selectedColor: selectedColor,
    selectedFrame: selectedFrame,
    quantity: 1
  }
}