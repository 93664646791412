import React from "react";
import styled from "styled-components";
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import {isMobile} from "react-device-detect";

const FooterContainer = styled.div<{isBlackAndWhite: boolean, isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  justify-content: space-around;
  background-color: #F9F9F9;
  padding: ${props => props.isMobile ? '5vw 10vw 10vw 10vw' : '2vw'};
  margin-top: ${props => props.isMobile ? '0' : '6vw'};
`

const RowContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${props => props.isMobile ? '5vw' : '3vw'};
`

const RowInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.2vw;
`

const FooterTitles = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '6vw' : '1.3vw'};
  font-weight: 500;
  margin-top: ${props => props.isMobile ? '5vw' : '0'}; 
`

const LighterText = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '3vw' : '0.8vw'};
`

const BoldText = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '4vw' : '0.8vw'};
  font-weight: 600;
`

const AppStoreContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: row;
  gap: ${props => props.isMobile ? '6vw' : '0.5vw'};
`

const AppStoreImage = styled.img`
  
`

const DownloadAppText = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '6vw' : '1.3vw'};
  font-weight: 500;
  margin: ${props => props.isMobile ? '8vw 0 4vw 0' : '1vw 0'};
`

const RightReserveContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: ${props => props.isMobile ? '8vw' : ''};
`

const RightReserveText = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '3vw' : '0.8vw'};
  max-width: ${props => props.isMobile ? '50vw' : '15vw'};
`

type FooterProps = {
  isBlackAndWhite?: boolean
}

const Footer = ({isBlackAndWhite}: FooterProps) => {
  return (
    <FooterContainer isMobile={isMobile} isBlackAndWhite={isBlackAndWhite || false}>
      <RowContainer isMobile={isMobile}>
        <FooterTitles isMobile={isMobile}>ABOUT US</FooterTitles>
        <RowInnerContainer>
          <LighterText isMobile={isMobile}>About</LighterText>
          <LighterText isMobile={isMobile}>FAQ</LighterText>
          <LighterText isMobile={isMobile}>Shipping</LighterText>
          <LighterText isMobile={isMobile}>Gifting</LighterText>
          <LighterText isMobile={isMobile}>Term of Service</LighterText>
          <LighterText isMobile={isMobile}>Contact us</LighterText>
        </RowInnerContainer>
      </RowContainer>
      <RowContainer isMobile={isMobile}>
        <FooterTitles isMobile={isMobile}>FOLLOW US</FooterTitles>
        <RowInnerContainer>
          <SocialMediaIcons />
          <DownloadAppText isMobile={isMobile}>DOWNLOAD APP</DownloadAppText>
          <AppStoreContainer isMobile={isMobile}>
            <AppStoreImage src='/images/apple-logo.svg' />
            <BoldText isMobile={isMobile}>App Store</BoldText>
          </AppStoreContainer>
          <AppStoreContainer isMobile={isMobile}>
            <AppStoreImage src='/images/android-logo.svg' />
            <BoldText isMobile={isMobile}>Play Store</BoldText>
          </AppStoreContainer>
        </RowInnerContainer>
      </RowContainer>
      <RightReserveContainer isMobile={isMobile}>
        <RightReserveText isMobile={isMobile}>
          All rights reserved  © 2023 The Baby Boutique   View our terms and conditions here
        </RightReserveText>
      </RightReserveContainer>
    </FooterContainer>
  )
}

export default Footer
