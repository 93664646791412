import React, {MouseEventHandler} from "react";
import styled from "styled-components";
import {isMobile} from "react-device-detect";

const ToggleButtonContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
  align-items: center;
  gap: ${isMobile ? '1vw' : '0.8vw'};
  width: ${isMobile ? '22vw' : ''};
  cursor: pointer;
`

export const Toggle = styled.div<{selected: boolean}>`
  height: ${isMobile ? '6vw' : '1.3vw'};
  width: ${isMobile ? '6vw' : '1.3vw'};
  border-radius: 50%;
  border: ${isMobile ? '1.2px' : '0.7px'} solid #B8A89B;
  background: ${props => props.selected ? '#846854' : ''};
`

const ToggleText = styled.label<{selected: boolean}>`
  color: ${props => props.selected ? '#846854' : '#B8A89B'};
  font-size: ${props => props.selected ? (isMobile ? '2.6vw' : '1vw') : (isMobile ? '2.4vw' : '0.9vw')};
  text-align: center;
  font-weight: 600;
`

type ToggleButtonProps = {
  text: string,
  selected: boolean,
  onClick: MouseEventHandler<HTMLDivElement>
}

const ToggleButton = ({text, selected, onClick}: ToggleButtonProps) => {
  return <ToggleButtonContainer onClick={onClick}>
    <Toggle selected={selected}/>
    <ToggleText selected={selected}>{text}</ToggleText>
  </ToggleButtonContainer>
}

export default ToggleButton
