import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {ColorCodeMapping, COLORS} from "../../constants/colors";
import {isMobile} from "react-device-detect";
import {Toggle} from "../ToggleButton/ToggleButton";
import {useNavigate, useParams} from "react-router-dom";
import {CartItemsContext} from "../../App";
import {getImagePath} from "../../utils/imageUtils";
import {CartItem} from "../../types/types";

const CartContainer = styled.div<{isMobile: boolean}>`
  padding: 5vw ${props => props.isMobile ? '5vw' : '20vw'};
  display: flex;
  flex-direction: column;
  background: #FDEFE6;
  justify-content: space-between;
  height: ${props => props.isMobile ? '88.5vh' : '85vh'};
`

const HeaderAndCartItem = styled.div`
  display: flex;
  flex-direction: column;
`

const ShippingAndAddressButton = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: row;
  border-bottom: 1.4px solid ${COLORS.lightBrown};
  justify-content: space-between;
  padding-bottom: ${props => props.isMobile ? '5vw' : '2vw'};
`

const HeaderText = styled.div<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  font-size: ${props => props.isMobile ? '5vw' : '1.5vw'};
  font-weight: 600;
  letter-spacing: 0.6vw;
`

const CrossIcon = styled.img`
`

const ItemsContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  max-height: 55vh;
  overflow: scroll;
  gap: ${props => props.isMobile ? '15vw' : '0'};
  margin-top: ${props => props.isMobile ? '10vw' : '0'};
`

const CartItemContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  gap: ${props => props.isMobile ? '0' : '10vw'};
`

const CartItemImg = styled.img<{isMobile: boolean}>`
  width: ${props => props.isMobile ? '30vw' : '10vw'};
  align-self: center;
  display: flex;
  margin-top: ${props => props.isMobile ? '0' : '2vw'};
  mix-blend-mode: multiply;
`

const CartItemDetails = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.isMobile ? '10vw' : '2vw'};
`

const CartItemTitle1 = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '4vw' : '1.3vw'};
  font-weight: 500;
  line-height: ${props => props.isMobile ? '6vw' : '2vw'};
  color: ${COLORS.lightBrown};
  text-transform: uppercase;
`

const CartItemTitle2 = styled.div<{isMobile: boolean}>`
  font-weight: 600;
  font-size: ${props => props.isMobile ? '4vw' : '1.3vw'};
  line-height: ${props => props.isMobile ? '6vw' : '2vw'};
  color: ${COLORS.lightBrown};
`

const CartItemColorAndFrame = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.isMobile ? '4vw' : '1vw'};
`

const CartItemColorText = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '2.7vw' : '1vw'};
  font-weight: 500;
  line-height: ${props => props.isMobile ? '6vw' : '2.2vw'};
  color: ${COLORS.lightBrown};
  letter-spacing: ${props => props.isMobile ? '0.4vw' : '0'};
  text-transform: uppercase;
`

const VerticalLine = styled.div`
  border-left: 1.5px solid ${COLORS.lightBrown};
  height: 1.3vh;
`

const ItemCountAndPrice = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'row' : 'column'};
  margin-top: ${props => props.isMobile ? '4vw' : '1vw'};
  justify-content: ${props => props.isMobile ? 'space-between' : ''};
  gap: ${props => props.isMobile ? '' : '1vw'}; 
`

const ItemCountContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: row;
  gap: ${props => props.isMobile ? '5vw' : '1.5vw'};
`

const ItemCountModifyText = styled.div<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  font-weight: 700;
  font-size: ${props => props.isMobile ? '5vw' : '1.2vw'};
  cursor: pointer;
`

const ItemCountText = styled.div<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  font-weight: 700;
  font-size: ${props => props.isMobile ? '5vw' : '1.2vw'};
`

const ItemPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5vw;
`

const ItemPriceCurrency = styled.div<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  font-weight: 700;
  font-size: ${props => props.isMobile ? '4vw' : '1.2vw'}
`

const ItemPriceText = styled.div<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  font-weight: 400;
  font-size: ${props => props.isMobile ? '4vw' : '1.2vw'}
`

const ShippingContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.isMobile ? '1vw' : '0.5vw'};
`

const ShippingTitle = styled.div<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  font-size: ${props => props.isMobile ? '4vw' : '1vw'};
  font-weight: 600;
`

const ShippingSubtitle = styled.div<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  font-size: ${props => props.isMobile ? '2vw' : '0.8vw'};
  font-weight: 400;
`

const SubTotalContainer = styled.div<{isMobile: boolean}>`
  margin-top: ${props => props.isMobile ? '4vw' : '2vw'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ContinueToAddressButton = styled.button<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  background-color: rgba(255, 255, 255, 0);
  line-height: ${props => props.isMobile ? '7vw' : '1.6vw'};
  border-radius: 5px;
  border: 1px solid #846854;
  margin-top: ${props => props.isMobile ? '3vw' : '1vw'};
  margin-bottom: ${props => props.isMobile ? '3vw' : '1vw'};
  font-size: ${props => props.isMobile ? '4vw' : '1.2vw'};
`

const Cart = () => {
  const [isMobileView, setIsMobileView] = useState(false)
  const productId = useParams().id || '0'
  const navigate = useNavigate()
  const cartContext = useContext(CartItemsContext)

  useEffect(() => {
    setIsMobileView(isMobile)
  }, [])

  const navigateToShipping = () => {
    navigate(`/shipping-address/${productId}`, {replace: true})
  }

  const addItemToCart = (item: CartItem) => {
    const updatedItems: CartItem[] = [...cartContext?.cartItems ?? []]
    const existingItem = updatedItems.filter(product => product.id === item.id)[0]
    if(existingItem) {
      existingItem.quantity += 1
      cartContext?.setCartItems(updatedItems)
    } else {
      cartContext?.setCartItems([...updatedItems, item])
    }
  }

  const removeItemFromCart = (itemId: string) => {
    const updatedItems: CartItem[] = [...cartContext?.cartItems ?? []]
    const matchedItemIndex = updatedItems.findIndex(item => item.id === itemId)
    updatedItems[matchedItemIndex].quantity < 2 ? updatedItems.splice(matchedItemIndex, 1) : updatedItems[matchedItemIndex].quantity--
    cartContext?.setCartItems(updatedItems)
  }

  let totalPrice = 0

  const renderCartItems = () => {
    return cartContext?.cartItems?.map(item => {
      totalPrice += (item.price * item.quantity)
      return <CartItemContainer isMobile={isMobileView}>
        <CartItemImg src={`/images/${getImagePath(item?.imagePath, item.selectedColor, item.selectedFrame)}`} isMobile={isMobileView}/>
        <CartItemDetails isMobile={isMobileView}>
          <CartItemTitle1 isMobile={isMobileView}>{item.title}</CartItemTitle1>
          <CartItemTitle2 isMobile={isMobileView}>{item.title2}</CartItemTitle2>
          <CartItemColorAndFrame isMobile={isMobileView}>
            <CartItemColorText isMobile={isMobileView}>COLOUR: </CartItemColorText>
            <Toggle
              selected={true}
              style={{
                background: ColorCodeMapping[item.selectedColor],
                border: isMobile ? `1.2px solid ${ColorCodeMapping[item.selectedColor]}` : `0.7px solid ${ColorCodeMapping[item.selectedColor]}`,
                height: isMobileView ? '3.5vw' : '1vw',
                width: isMobileView ? '3.5vw' : '1vw'
              }}
            />
            {!isMobileView && <CartItemColorText isMobile={isMobileView}>{item.selectedColor}</CartItemColorText>}
            <VerticalLine/>
            <CartItemColorText isMobile={isMobileView}>FRAME: </CartItemColorText>
            <Toggle
              selected={true}
              style={{
                background: ColorCodeMapping[item.selectedFrame],
                border: isMobile ? `1.2px solid ${ColorCodeMapping[item.selectedFrame]}` : `0.7px solid ${ColorCodeMapping[item.selectedFrame]}`,
                height: isMobileView ? '3.5vw' : '1vw',
                width: isMobileView ? '3.5vw' : '1vw'
              }}
            />
            {!isMobileView && <CartItemColorText isMobile={isMobileView}>{item.selectedFrame}</CartItemColorText>}
          </CartItemColorAndFrame>
          <ItemCountAndPrice isMobile={isMobileView}>
            <ItemCountContainer isMobile={isMobileView}>
              <ItemCountModifyText onClick={() => removeItemFromCart(item.id)} isMobile={isMobileView}>-</ItemCountModifyText>
              <ItemCountText isMobile={isMobileView}>{item.quantity}</ItemCountText>
              <ItemCountModifyText onClick={() => addItemToCart(item)} isMobile={isMobileView}>+</ItemCountModifyText>
            </ItemCountContainer>
            <ItemPriceContainer>
              <ItemPriceCurrency isMobile={isMobileView}>AED</ItemPriceCurrency>
              <ItemPriceText isMobile={isMobileView}>{`${item.price * item.quantity}.00`}</ItemPriceText>
            </ItemPriceContainer>
          </ItemCountAndPrice>
        </CartItemDetails>
      </CartItemContainer>
    })
  }

  return <CartContainer isMobile={isMobileView}>
    <HeaderAndCartItem>
      <Header isMobile={isMobileView}>
        <HeaderText isMobile={isMobileView}>CART</HeaderText>
        <CrossIcon src='/images/close-button.svg' onClick={() => navigate(-1)}/>
      </Header>
      <ItemsContainer isMobile={isMobileView}>
        {renderCartItems()}
      </ItemsContainer>
    </HeaderAndCartItem>
    <ShippingAndAddressButton>
      <ShippingContainer isMobile={isMobileView}>
        <ShippingTitle isMobile={isMobileView}>Shipping</ShippingTitle>
        <ShippingSubtitle isMobile={isMobileView}>Free Shipping within UAE. Orders ships in 3-7 working days. Orders to all other destinations do not include tax and may be subject to tax and duties on delivery. Tax rates are based on the rate of your country..</ShippingSubtitle>
      </ShippingContainer>
      <SubTotalContainer isMobile={isMobileView}>
        <ShippingTitle isMobile={isMobileView}>SUBTOTAL</ShippingTitle>
        <ShippingTitle isMobile={isMobileView}>{`${totalPrice}.00`}</ShippingTitle>
      </SubTotalContainer>
      <ContinueToAddressButton onClick={navigateToShipping} isMobile={isMobileView}>Continue to address</ContinueToAddressButton>
    </ShippingAndAddressButton>
  </CartContainer>
}

export default Cart
