import React from "react";
import styled from "styled-components";
import {ColorCodeMapping} from "../../constants/colors";

const ColorStripContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8vw;
`

export const ColorCircle = styled.div<{color: string}>`
  background: ${props => props.color};
  border-radius: 50%;
  height: 1.3vw;
  width: 1.3vw;
  cursor: pointer;
  stroke: #BDBDBD;
  stroke-width: 0.5px;
  filter: drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.20));
`

export const ColorCircleUnselected = styled.div<{color: string}>`
  background: ${props => props.color};
  border-radius: 50%;
  height: 0.7vw;
  width: 0.7vw;
  border: 0.3vw solid #F3F3F3;
  cursor: pointer;
`

type ColorStripProps = {
  colors: string[],
  selectedColor: string,
  setSelectedColor: (color: string) => void
}

const ColorStrip = ({colors, selectedColor, setSelectedColor}: ColorStripProps) => {


  return <ColorStripContainer>
    {colors.map(color => {
      return selectedColor === color ? <ColorCircle key={color} onClick={() => setSelectedColor(color)} color={ColorCodeMapping[color]}></ColorCircle> :
        <ColorCircleUnselected key={color} onClick={() => setSelectedColor(color)} color={ColorCodeMapping[color]}></ColorCircleUnselected>
    })}
  </ColorStripContainer>
}

export default ColorStrip
