const breakpoints = {
  xs: "320px",
  sm: "462px",
  lg: "1024px"
}

export const devices = {
  xs: `(max-width: ${breakpoints.xs})`,
  sm: `(max-width: ${breakpoints.sm})`,
  lg: `(max-width: ${breakpoints.lg})`
}
