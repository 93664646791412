import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";

const ProductContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.isMobile ? '68vw' : '21vw'};
  height: ${props => props.isMobile ? '76vw' : '23.5vw'};
  margin-left: ${props => props.isMobile ? '3vw' : '0'}
`

const ProductImage = styled.img<{isMobile: boolean}>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
`

const TitleAndPrice = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
`

const TitleText = styled.div<{isMobile: boolean}>`
  color: #846854;
  letter-spacing: 0.1vw;
  font-size: ${props => props.isMobile ? '3vw' : '0.9vw'};
  font-weight: 700;
  line-height: ${props => props.isMobile ? '7vw' : '2vw'};
`

const PriceText = styled.div<{isMobile: boolean}>`
  color: #846854;
  letter-spacing: 0.1vw;
  font-size: ${props => props.isMobile ? '2.5vw' : '0.7vw'};
  font-weight: 400;
`

type ProductProps = {
  id: string;
  title: string;
  price: string;
  imagePath: string;
}

const Product = ({id, title, price, imagePath}: ProductProps) => {
  const [isMobileDisplay, setIsMobileDisplay] = useState(isMobile)

  useEffect(() => {
    setIsMobileDisplay(isMobile)
  }, []);

  const navigate = useNavigate();
  const redirectToProductDetailPage = () => {
    navigate(`/products/${id}`)
  }

  return (
    <ProductContainer isMobile={isMobileDisplay} onClick={redirectToProductDetailPage}>
      <ProductImage src={`/images/${imagePath}`} isMobile={isMobileDisplay}/>
      <TitleAndPrice isMobile={isMobileDisplay}>
        <TitleText isMobile={isMobileDisplay}>{title}</TitleText>
        <PriceText isMobile={isMobileDisplay}>{price}</PriceText>
      </TitleAndPrice>
    </ProductContainer>
  )
}

export default Product
