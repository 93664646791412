import React from "react";
import styled from "styled-components";
import {devices} from "../../constants/deviceSizes";
import {isMobile} from "react-device-detect";

const SocialMediaContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: row;
  gap: ${props => props.isMobile ? '6vw' : '1rem'};
`

const SocialMediaImage = styled.img<{isMobile: boolean}>`
  height: ${props => props.isMobile ? '10vw' : '1.8vw'};
  @media only screen and ${devices.sm} {
    height: ${props => props.isMobile ? '6vw' : '3.2vw'};
  }
`

const SocialMediaIcons = () => {
  return (
    <SocialMediaContainer isMobile={isMobile}>
      <SocialMediaImage isMobile={isMobile} src="/images/facebook.svg"/>
      <SocialMediaImage isMobile={isMobile} src="/images/instagram.svg"/>
      <SocialMediaImage isMobile={isMobile} src="/images/pinterest.svg"/>
      <SocialMediaImage isMobile={isMobile} src="/images/twitter.svg"/>
    </SocialMediaContainer>
  )
}

export default SocialMediaIcons
