import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {isMobile} from "react-device-detect";
import {COLORS} from "../../constants/colors";
import {Toggle} from "../ToggleButton/ToggleButton";
import {useParams} from "react-router-dom";
import {getImagePath} from "../../utils/imageUtils";
import {CartItemsContext, ProductsContext} from "../../App";
import {CartItem} from "../../types/types";
import {object, string} from "yup";

const ShippingAddressContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  background: linear-gradient(0deg, rgba(254, 228, 231, 0.76) 1.76%, rgba(255, 255, 255, 0.24) 66.36%);
  padding-bottom: ${props => props.isMobile ? '25vw' : '4vw'};
  width: 100%;
`

const LeftSideContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  margin: ${props => props.isMobile ? '0 5%' : '0 10%'};
  width: ${props => props.isMobile ? '' : '40%'};
`

const HeaderImg = styled.img<{isMobile: boolean}>`
  height: ${props => props.isMobile ? '40vw' : '10vw'};
`

const ShippingDetailsText = styled.div`
  color: ${COLORS.lightBrown};
  text-align: center;
  font-size: 6vw;
  font-weight: 600;
  text-shadow: 0 5px 19px rgba(144, 102, 102, 0.10);
  font-family: Amethysta,serif;
  margin-bottom: 15vw;
`

const AddressFormContainer = styled.form<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.isMobile ? '4vw' : '1vw'};
`

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 34%;
  margin-right: 10%;
`

const ShoppingBagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.5vw;
  margin-top: 2vw;
`

const DiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5vw;
  gap: 0.6vw;
`

const DiscountRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1vw;
`

const DiscountInputArea = styled.input`
  color: var(--light-brown, #846854);
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.7px;
  padding-left: 0.7vw;
  width: 27vw;
  border: 0.5px solid ${COLORS.lightBrown};
  border-radius: 3.5px;
  &:focus {
    outline: none;
  }
`

const DiscountApplyButton = styled.button`
  background: #46332D;
  border-radius: 5px;
  line-height: 2vw;
  font-size: 0.8vw;
  padding: 0 4vw;
  color: ${COLORS.white};
`

const ShoppingImg = styled.img`
  height: 3.5vw;
`

const ShopItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ShopItemTitle = styled.div`
  font-size: 0.8vw;
  color: ${COLORS.lightBrown};
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
`

const DiscountRelatedLabel = styled.div`
  color: var(--light-brown, #846854);
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 900;
  line-height: 191.9%;
  letter-spacing: 1.8px;
`

const PricingText = styled.div`
  color: #846854;
  text-align: right;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`

const PriceText = styled.div`
  color: ${COLORS.lightBrown};
  font-size: 0.8vw;
`

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5vw;
  align-items: center;
`

const ColorText = styled.div`
  color: ${COLORS.lightBrown};
  font-size: 0.7vw;
`

const VerticalLine = styled.div`
  border-right: 1px solid ${COLORS.lightBrown};
  height: 1vw;
`

const QuantityText = styled.div`
  color: ${COLORS.lightBrown};
  font-size: 1vw;
  font-weight: 700;
  display: flex;
  align-items: center;
`

const InputBoxContainer = styled.div<{direction: string}>`
  display: flex;
  flex-direction: ${props => props.direction};
  border: 0.75px solid ${COLORS.lightBrown};
  border-radius: 4px;
  align-items: ${props => props.direction === 'column' ? 'initial' : 'center'};
  background: white;
`

const InputBoxLabel = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '4vw' : '0.8vw'};
  font-style: normal;
  font-weight: 400;
  line-height: ${props => props.isMobile ? '7vw' : '1.5vw'};
  color: ${COLORS.lightBrown};
  padding-left: 0.5vw;
`

const InputField = styled.input<{singleLine: boolean, isMobile: boolean}>`
  padding-left: ${props => props.singleLine ? '5vw' : '0.5vw'};
  line-height: ${props => props.isMobile ? '7vw' : '1.6vw'};
  font-size: ${props => props.isMobile ? '4vw' : '0.8vw'};
  border: 0;
  color: var(--80-black, ${COLORS.black});
  &:focus {
    outline: none;
  }
`

const InputArea = styled.textarea<{singleLine: boolean, isMobile: boolean}>`
  padding-left: ${props => props.singleLine ? '5vw' : '0.5vw'};
  line-height: ${props => props.isMobile ? '7vw' : '1.6vw'};
  font-size: ${props => props.isMobile ? '4vw' : '0.8vw'};
  border: 0;
  color: var(--80-black, ${COLORS.black});
  font-family: inherit;
  resize: none;
  &:focus {
    outline: none;
  }
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

type InputBoxProps = {
  id: string
  label: string
  value: string
  labelAlignment: string
  placeholder: string
  isMobileView: boolean
  width?: string
  type: string
  onFieldChange: (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void
}

const HeaderText = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '4.5vw' : '1.2vw'};
  font-weight: 400;
  color: var(--80-black, ${COLORS.black});
  line-height: 125.4%;
  letter-spacing: 0.54px;
  margin-top: ${props => props.isMobile ? '4vw' : '1vw'};;
`

const ContinueShippingButton = styled.button<{isMobile: boolean, disabled: boolean}>`
  border-radius: 5px;
  background: ${props => props.disabled ? '#dddddd' : '#46332D'};
  color: white;
  font-size: ${props => props.isMobile ? '4vw' : '1.2vw'};
  line-height: ${props => props.isMobile ? '8vw' : '2.4vw'};;
  border: none;
`

const RightSideHeader = styled.div`
  border-bottom: 1px solid ${COLORS.lightBrown};
  margin-top: 1vw;
`

const RightSideHeaderText = styled.div`
  font-size: 0.9vw;
  color: ${COLORS.lightBrown};
  font-weight: 900;
  line-height: 3vw;
`

const ShoppingBagItemsContainer = styled.div`
  max-height: 25vh;
  overflow: scroll;
`

const InputBox = ({id, label, value, labelAlignment, placeholder, isMobileView, width, type, onFieldChange}: InputBoxProps) => {
  return <InputBoxContainer direction={labelAlignment}>
    <InputBoxLabel isMobile={isMobileView}>{label}</InputBoxLabel>
    { type === 'input' ?
        <InputField id={id} onChange={onFieldChange} singleLine={labelAlignment === 'row'} value={value} placeholder={placeholder} isMobile={isMobileView}></InputField>
        : <InputArea id={id} onChange={onFieldChange} singleLine={labelAlignment === 'row'} value={value} placeholder={placeholder} isMobile={isMobileView}></InputArea>
    }
  </InputBoxContainer>
}

const renderShoppingBagItems = (cartItems: CartItem[], isMobileView: boolean) => {
  return cartItems.map(item => {
    return <ShoppingBagContainer key={item.id}>
      <ShoppingImg src={`/images/${getImagePath(item?.imagePath, item.selectedColor, item.selectedFrame)}`}/>
      <QuantityText>1</QuantityText>
      <ShopItemContainer>
        <div>
          <ShopItemTitle>{item.title}</ShopItemTitle>
          <ShopItemTitle>{item.title2}</ShopItemTitle>
        </div>
        <ColorsContainer>
          <ColorText >COLOUR</ColorText>
          <Toggle
            selected
            style={{
              background: COLORS.ginger,
              border: isMobile ? `1.2px solid ${COLORS.ginger}` : `0.7px solid ${COLORS.ginger}`,
              height: isMobileView ? '3vw' : '1vw',
              width: isMobileView ? '3vw' : '1vw'
            }}
          />
          <ColorText>GINGER</ColorText>
          <VerticalLine/>
          <ColorText >FRAME</ColorText>
          <Toggle
            selected
            style={{
              background: COLORS.white,
              border: isMobile ? `1.2px solid ${COLORS.offWhite}` : `0.7px solid ${COLORS.offWhite}`,
              height: isMobileView ? '3vw' : '1vw',
              filter: 'drop-shadow(0px 0px 2.4729442596435547px rgba(0, 0, 0, 0.10))',
              width: isMobileView ? '3vw' : '1vw'
            }}
          />
          <ColorText>WHITE</ColorText>
        </ColorsContainer>
      </ShopItemContainer>
      <PriceText>{`AED ${item.price}.00`}</PriceText>
    </ShoppingBagContainer>
  })
}

const ShippingAddress = () => {
  const [isMobileView, setIsMobileView] = useState(false)
  const params = useParams()
  const productId = params.id || '0'
  const products = useContext(ProductsContext)
  const selectedProduct = products?.find(product => product._id === productId)!
  const cartContext = useContext(CartItemsContext)
  const cartItems = cartContext?.cartItems.map(item =>
    ({id: item.id, name: item.title, quantity: item.quantity}))
  const [showSubmitBtn, setShowSubmitBtn] = useState(false)
  const shippingAddressSchema = object({
    email: string().required(),
    firstName: string().required(),
    lastName: string().required(),
    company: string().notRequired(),
    address: string().required(),
    building: string().required(),
    city: string().required(),
    emirate: string().required(),
    phone: string().required(),
  });
  const [shippingAddress, setShippingAddress] = useState({
    email: "",
    firstName: "",
    lastName: "",
    company: "",
    address: "",
    building: "",
    city: "",
    emirate: "",
    phone: ""
  });

  const onFieldChange = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    setShippingAddress({...shippingAddress, [event.target.id]: value});
    const shouldShowSubmitBtn = await shippingAddressSchema.isValid(shippingAddress)
    setShowSubmitBtn(shouldShowSubmitBtn)
  };

  const navigateToPayment = () => {
    const requestBody = {
      email: shippingAddress.email,
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      company: shippingAddress.company,
      address: {
        locality: shippingAddress.address,
        building: shippingAddress.building,
        city: shippingAddress.city,
        emirates: shippingAddress.emirate
      },
      phone: shippingAddress.phone
    }
    fetch(`${process.env.REACT_APP_SERVER_URI}/users`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }).then(async data => {
      console.log('User created')
      const createdUser = await data.json()
      const apiUrl = `${process.env.REACT_APP_SERVER_URI}/payment/create-checkout-session`;
      fetch(apiUrl, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          userId: createdUser._id,
          items: cartItems
        })
      }).then(res => {
        console.log('response from server: ', res)
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
      }).then(({url}) => {
        window.location = url
      }).catch(e => {
        console.error(e.error)
      })
    })
  }

  useEffect(() => {
    setIsMobileView(isMobile)
  }, []);
  return <ShippingAddressContainer isMobile={isMobileView}>
    <LeftSideContainer isMobile={isMobileView}>
      <HeaderImg src='/images/logo.svg' isMobile={isMobileView}/>
      {isMobileView && <ShippingDetailsText>SHIPPING DETAILS</ShippingDetailsText>}
      <AddressFormContainer onSubmit={(event: { preventDefault: () => void; }) => event.preventDefault()} isMobile={isMobileView}>
        <HeaderText isMobile={isMobileView}>Contact information</HeaderText>
        <InputBox
          id='email'
          label='email'
          value={shippingAddress.email}
          placeholder='test@gmail.com'
          labelAlignment='row'
          isMobileView={isMobileView}
          type='input'
          onFieldChange={onFieldChange}
        />
        <HeaderText isMobile={isMobileView}>Shipping address</HeaderText>
        <NameContainer>
          <InputBox
            id='firstName'
            label='First name'
            value={shippingAddress.firstName}
            labelAlignment='column'
            placeholder='Joe'
            isMobileView={isMobileView}
            type='input'
            onFieldChange={onFieldChange}
          />
          <InputBox
            id='lastName'
            label='Last name'
            value={shippingAddress.lastName}
            labelAlignment='column'
            placeholder='Andrea'
            isMobileView={isMobileView}
            type='input'
            onFieldChange={onFieldChange}
          />
        </NameContainer>
        <InputBox
          id='company'
          label='Company (optional)'
          value={shippingAddress.company}
          placeholder='The Baby Boutique'
          labelAlignment='column'
          isMobileView={isMobileView}
          type='input'
          onFieldChange={onFieldChange}
        />
        <InputBox
          id='address'
          label='Address'
          value={shippingAddress.address}
          placeholder='Tamm street villa 1, Zee Residence,  Dubai , United Arab Emirates'
          labelAlignment='column'
          isMobileView={isMobileView}
          type='area'
          onFieldChange={onFieldChange}
        />
        <InputBox
          id='building'
          label='Building / Apartment number (optional)'
          value={shippingAddress.building}
          placeholder='05 - Vill 32'
          labelAlignment='column'
          isMobileView={isMobileView}
          type='input'
          onFieldChange={onFieldChange}
        />
        <NameContainer>
          <InputBox
            id='city'
            label='City'
            value={shippingAddress.city}
            labelAlignment='column'
            placeholder='Joe'
            isMobileView={isMobileView}
            type='input'
            onFieldChange={onFieldChange}
          />
          <InputBox
            id='emirate'
            label='Emirates'
            value={shippingAddress.emirate}
            labelAlignment='column'
            placeholder='Andrea'
            isMobileView={isMobileView}
            type='input'
            onFieldChange={onFieldChange}
          />
        </NameContainer>
        <InputBox
          id='phone'
          label='Phone'
          value={shippingAddress.phone}
          placeholder='+971 55 123 1234'
          labelAlignment='column'
          isMobileView={isMobileView}
          type='input'
          onFieldChange={onFieldChange}
        />
        <ContinueShippingButton disabled={!showSubmitBtn} type='button' onClick={navigateToPayment} isMobile={isMobileView}>continue to shipping</ContinueShippingButton>
      </AddressFormContainer>
    </LeftSideContainer>
    {!isMobileView && <RightSideContainer>
      <RightSideHeader>
        <RightSideHeaderText>SHOPPING BAG</RightSideHeaderText>
      </RightSideHeader>
      <ShoppingBagItemsContainer>
        {renderShoppingBagItems(cartContext?.cartItems ?? [], isMobileView)}
      </ShoppingBagItemsContainer>
      <DiscountContainer>
        <DiscountRow>
          <DiscountInputArea placeholder='discount code or gift card'/>
          <DiscountApplyButton>Apply</DiscountApplyButton>
        </DiscountRow>
        <DiscountRow>
          <DiscountRelatedLabel>Subtotal</DiscountRelatedLabel>
          <PricingText>{`AED ${selectedProduct.price}.00`}</PricingText>
        </DiscountRow>
        <DiscountRow>
          <DiscountRelatedLabel>Shipping</DiscountRelatedLabel>
          <PricingText>AED 0</PricingText>
        </DiscountRow>
        <DiscountRow>
          <DiscountRelatedLabel>TOTAL</DiscountRelatedLabel>
          <PricingText style={{fontWeight: '700'}}>{`AED ${selectedProduct.price}.00`}</PricingText>
        </DiscountRow>
      </DiscountContainer>
    </RightSideContainer>}
  </ShippingAddressContainer>
}

export default ShippingAddress