import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import Product from "../Product/Product";
import Header from "../Header/Header";
import {isMobile} from "react-device-detect";
import {CartItemsContext, ProductsContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {getImagePath} from "../../utils/imageUtils";
import Categories from "../Categories/Categories";

const ProductsPageContainer = styled.div<{isMobile: boolean}>`
  background: linear-gradient(#FFFFFF, #FEE4E7);
  padding-bottom: ${props => props.isMobile ? '18vw' : '0'};
`

const ProductsContainer = styled.div<{isMobile: boolean}>`
  margin: 2% 10%;
  display: grid;
  grid-template-columns: ${props => props.isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'};
  column-gap: 7vw;
  row-gap: ${props => props.isMobile ? '30vw' : '10vw'};
`

const Icon = styled.img`
  height: 1.7vw;
`

export const BagContainer = styled.div`
  position: relative;
  cursor: pointer;
`

export const BagItemCount = styled.div`
  position: absolute;
  top: 0.7vw;
  font-size: 0.6vw;
  left: 1.36vw;
`

const LogoAndFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vw;
`

export const LogoImage = styled.img`
  height: 5vw
`

const ProductsPageTitleAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
  gap: 0.7vw;
`

const ProductsPageTitle = styled.div`
  font-size: 2vw;
  color: #846854;
  font-family: amstelvarAlpha;
  letter-spacing: 0.2vw;
`

const ProductsPageSubtitle = styled.div`
  font-size: 0.8vw;
  color: #846854;
  font-family: amstelvarAlpha;
  letter-spacing: 0.1vw;
`

const FilterAndSort = styled.div`
  border-top: 1px solid #FEE4E7;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
`

const FilterSortText = styled.div`
 color: #846854;
 font-size: 1vw;
 font-family: amstelvarAlpha;
 letter-spacing: 0.1vw;
 margin: 0.5% 4%;
`

const BottomDiv = styled.div`
  height: ${isMobile ? '15vw' : '4vw'};
  background-color: #FEE4E7;
`

const ProductsPage = () => {

  const [isMobileDisplay, setIsMobileDisplay] = useState(isMobile)
  const cartContext = useContext(CartItemsContext)
  const navigate = useNavigate()
  const [tag, setTag] = useState('all new')
  const products = useContext(ProductsContext);
  const filteredProducts = products?.filter(product => product.tags.includes(tag.toLowerCase()))

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMobileDisplay(isMobile)
  }, []);

  return (
    <ProductsPageContainer isMobile={isMobileDisplay}>
      {!isMobileDisplay && <Header
        leftItems={[<Icon src='/images/help-icon.svg' />, <Icon src='/images/earth-icon.svg' />]}
        centerItem={
        <LogoAndFilters>
        <LogoImage src='/images/logo-small.svg'/>
          <Categories
            setTag={setTag}
            categories={['ALL NEW', 'AGE', 'STROLLERS', 'GIRLS', 'BOYS', 'ACCESSORIES', 'ALL BRANDS', 'MOVE', 'LAST IN STOCK']}/>
        </LogoAndFilters>
      }
        rightItems={[
          <BagContainer onClick={() => navigate(`/cart/1`)}>
            <Icon src='/images/shopping-bag.svg' />
            <BagItemCount>{cartContext?.cartItems?.length}</BagItemCount>
          </BagContainer>,
          <Icon src='/images/profile-icon.svg' />
        ]}
      />}
      <ProductsPageTitleAndSubtitle>
        {!isMobileDisplay && <ProductsPageTitle>STROLLERS</ProductsPageTitle>}
        {!isMobileDisplay&& <ProductsPageSubtitle>Select from a range of stylish high end strollers from some of our favourite top brands</ProductsPageSubtitle>}
      </ProductsPageTitleAndSubtitle>
      {!isMobileDisplay && <FilterAndSort>
        <FilterSortText>Filter</FilterSortText>
        <FilterSortText>Sort</FilterSortText>
      </FilterAndSort>}
      <ProductsContainer isMobile={isMobileDisplay}>
        {filteredProducts?.map((product, i) => <Product
          id={product._id}
          key={`${i}-product`}
          title={product.title}
          price={`AED ${product.price}.00`}
          imagePath={getImagePath(product.imagePath, product.availableColors[0])}
        />)}
      </ProductsContainer>
      <BottomDiv/>
    </ProductsPageContainer>
  )
}

export default ProductsPage
