import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import ToggleButton from "../ToggleButton/ToggleButton";
import {isMobile} from "react-device-detect";
import {CartItemsContext, ProductsContext} from "../../App";
import {ColorCodeMapping, COLORS} from "../../constants/colors";
import BecomeMember from "../BecomeMember/BecomeMember";
import Footer from "../Footer/Footer";
import {useNavigate, useParams} from "react-router-dom";
import {CartItem, Product} from "../../types/types";
import {convertToCartItem} from "../../utils/productToCartItemUtils";
import {getImagePath} from "../../utils/imageUtils";
import {ColorCircle} from "../ColorStrip/ColorStrip";

const Container = styled.div`
  background: linear-gradient(#FFFFFF, #FEE4E7);
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProductImgContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: 5%;
`

const ProductImg = styled.img`
  height: 85vw;
  mix-blend-mode: multiply;
  z-index: 1000;
`

const ProductImgBackground = styled.div<{color: string}>`
  border-radius: 171px;
  background: linear-gradient(0deg, rgba(254, 237, 239, 0.00) 46.35%, ${props => `${props.color}78`} 100%);
  box-shadow: 0px -20px 20px -8px rgba(132, 104, 84, 0.25);
  height: 80vw;
  width: 76vw;
  position: absolute;
  top: 3vw;
  left: 5vw;
  z-index: 100;
`

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7%;
  align-items: center;
  width: 80%;
`

const ProductTitle = styled.div`
  color: #846854;
  font-size: 5vw;
  letter-spacing: 0.2vw;
  text-align: center;
  font-weight: 500;
  width: 100%;
`

const ProductPrice = styled.div`
  color: #846854;
  letter-spacing: 0.1vw;
  margin-top: 2%;
  font-size: 3.5vw;
  font-weight: 600;
  text-align: center;
`

const ProductPriceOrDetails = styled.div`
  color: #846854;
  letter-spacing: 0.1vw;
  font-size: 2.5vw;
  font-weight: 500;
  text-align: center;
`

const DescriptionTitle = styled.div`
  margin-top: 2.5vw;
  color: #846854;
  font-weight: 500;
  font-size: 2vw;
  text-align: center;
`

const BuyNowButton = styled.button`
  border: 0.9px #46332D solid;
  border-radius: 3px;
  color: #46332D;
  background: rgba(255,255,255,0);
  margin-top: 5%;
  line-height: 6vw;
  font-size: 4.5vw;
  letter-spacing: 0.1vw;
  font-family: amstelvalalpha;
  width: 100%;
  height: 10vw;
  align-items: center;
  display: flex;
  justify-content: center;
`

const MoreActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  height: 10vw;
  column-gap: 2vw;
  align-items: center;
  margin-top: 2vw;
`

const HeartIcon = styled.img`
  width: 6vw;
`

export const InfoIcon = styled.img<{isMobile: boolean}>`
  height: ${props => props.isMobile ? '7vw' : '1.6vw'};
  width: ${props => props.isMobile ? '7vw' : '1.6vw'};
`

const ToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 7vw;
  margin-top: 5%;
`

const BottomDiv = styled.div`
  height: ${isMobile ? '15vw' : '4vw'};
  background-color: #FEE4E7;
`

const MoreDetailViewContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15vw;
  margin-top: ${props => props.isMobile ? '0' : '8vw'};
`

const ProductImagesContainer = styled.div<{direction: string, paddingLeft: string, paddingRight: string}>`
  display: flex;
  flex-direction: ${props => props.direction};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
`

const ProductImage1 = styled.img<{isMobile: boolean}>`
  height: ${props => props.isMobile ? '82vw' : '38vw'};
  width: ${props => props.isMobile ? '100%' : '35vw'};
` 

const ProductImage2 = styled.img<{isMobile: boolean}>`
  height: ${props => props.isMobile ? '45vw' : '14vw'};
  width: ${props => props.isMobile ? '75%' : '20vw'};
  margin-left: ${props => props.isMobile ? '10vw' : '5vw'};
` 

const ProductImage3 = styled.img<{isMobile: boolean}>`
  height: ${props => props.isMobile ? '82vw' : '38vw'};
  width: ${props => props.isMobile ? '100%' : '35vw'};
` 

const ProductDetailTextContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vw;
  margin-bottom: ${props => props.isMobile ? '10vw' : '4vw'};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`

const TextHeading = styled.div<{isMobile: boolean}>`
  color: ${COLORS.lightBrown};
  font-size: ${props => props.isMobile ? '5vw' : '1.5vw'};
`

const TextSubHeading = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: row;
  color: ${COLORS.lightBrown};
  font-size: ${props => props.isMobile ? '3vw' : '1vw'};
  margin-top: 3%;
  letter-spacing: ${props => props.isMobile ? '0.2vw' : '0.1vw'};
  gap: 1.2vw;
`

const BulletIcon = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${COLORS.lightBrown};
  margin-top: 4px;
`

const BassinetImage = styled.img<{isMobile: boolean}>`
  height: ${isMobile ? '100%' : '44vw'};
  width: ${isMobile ? '100%' : '32vw'};
  margin-left: ${isMobile ? '0' : '5vw'};
  display: flex;
  object-fit: cover;

`

const Bassinet3Images = styled.img<{isMobile: boolean}>`
  height: ${isMobile ? '100%' : '40vw'};
  width: ${isMobile ? '100%' : '33.34vw'};
  margin-left: 0;
  display: flex;
  object-fit: cover;

`

const MoreItemsContainer = styled.div<{isMobile:boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  gap: ${props => props.isMobile ? '5vw' : '8vw'};
  justify-content: center;
`

const MoreItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6vw;
  align-items: center;
`

const MoreItemImg = styled.img<{isMobile:boolean}>`
  width: ${props => props.isMobile ? '80vw' : '20vw'};
`

const MoreItemTitle = styled.div<{isMobile: boolean}>`
  font-size: ${props => props.isMobile ? '6vw' : '1.2vw'};
  color: ${COLORS.lightBrown};
  font-weight: 800;
`

const PlusIcon = styled.div`
  color: #46332D;
  text-align: center;
  font-weight: 700;
  font-size: 47.04px;
  line-height: 84.4%;
  margin-top: 5vw;
  margin-bottom: 5vw;
`

const FooterContainer = styled.div`
  margin-top: 10vw;
`

const ColorSelectionContainer = styled.div<{expanded: boolean}>`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${props => props.expanded ? 'transform 330ms ease-in-out' : ''};
  transform: ${props => props.expanded ? 'translateY(100vh)' : ''};
  top: -100vh;
  background-color: rgba(240, 225, 225, 0.8);
  z-index: 1000;
`

const ColorSelectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  margin-top: 5vw;
  width: 100%;
`

export const renderMoreDetailView = (isMobileView: boolean) => {
  return <MoreDetailViewContainer isMobile={isMobileView}>
    <div style={{display: 'flex', flexDirection: isMobileView ? 'column' : 'row'}}>
      <ProductImagesContainer direction='column' paddingLeft={isMobileView ? '10%' : '8%'} paddingRight={isMobileView ? '10%' : '3%'}>
        <ProductImage1 isMobile={isMobileView} src="/images/stroller-img1.jpg"/>
        <ProductImage2 isMobile={isMobileView} src="/images/stroller-img2.jpg"/>
        <ProductImage3 isMobile={isMobileView} src="/images/stroller-img3.jpg"/>
      </ProductImagesContainer>
      <ProductImagesContainer  direction='column' paddingLeft={isMobileView ? '0%' : '3%'} paddingRight={isMobileView ? '0%' : '8%'}>
        <ProductDetailTextContainer isMobile={isMobileView}>
        <TextContainer>
          <TextHeading isMobile={isMobileView}>Details</TextHeading>
          <TextSubHeading isMobile={isMobileView}>
            <BulletIcon/> Ultra-lightweight bassinet, 3 kg (6.7 lbs).
          </TextSubHeading>
          <TextSubHeading isMobile={isMobileView}>
            <BulletIcon/> Ultra-compact, disassembles into two half-shells for optimal storage.
          </TextSubHeading>
          <TextSubHeading isMobile={isMobileView}>
            <BulletIcon/> Comfortable and breathable 4.5 cm (1.8 in) thick double mattress.Ventilated shell and canopy.
          </TextSubHeading>
          <TextSubHeading isMobile={isMobileView}>
            <BulletIcon/> Semi-hard shell and enveloping foot cover.Inner pockets to tuck away small items.
          </TextSubHeading>
          <TextSubHeading isMobile={isMobileView}>
            <BulletIcon/> Anti-UV (UPF 50+) water-repellent fabric.
          </TextSubHeading>
        </TextContainer>
        <TextContainer>
          <TextHeading isMobile={isMobileView}>Shipping</TextHeading>
          <TextSubHeading isMobile={isMobileView}>Free Shipping within UAE Orders ships in 2-7 working days Orders to all other destinations do not include tax and may be subject to tax and duties on delivery. Tax rates are based on the rate of your country..</TextSubHeading>
        </TextContainer>
        <TextContainer>
          <TextHeading isMobile={isMobileView}>Returns</TextHeading>
          <TextSubHeading isMobile={isMobileView}>14 days from the delivery date to request and dispatch a return. To send something back you can process the return automatically here: tbb.returnscenter.com</TextSubHeading>
        </TextContainer>
        </ProductDetailTextContainer>
        <BassinetImage isMobile={isMobileView} src='/images/bassinet.jpg'/>
      </ProductImagesContainer>
    </div>
    <ProductImagesContainer direction={isMobileView ? 'column' : 'row'} paddingLeft={isMobileView ? '0%' : '0%'} paddingRight={isMobileView ? '0%' : '0%'}>
      <Bassinet3Images isMobile={isMobileView} src='/images/bassinet-1.jpg'/>
      <Bassinet3Images isMobile={isMobileView} src='/images/bassinet-2.jpg'/>
      <Bassinet3Images isMobile={isMobileView} src='/images/bassinet-3.jpg'/>
    </ProductImagesContainer>
    <PlusIcon>+</PlusIcon>
    <MoreItemsContainer isMobile={isMobileView}>
      <MoreItemContainer>
        <MoreItemImg isMobile={isMobileView} src='/images/yolo-car-seat.jpg'/>
        <MoreItemTitle isMobile={isMobileView}>YOYO car seat</MoreItemTitle>
      </MoreItemContainer>
      <MoreItemContainer>
        <MoreItemImg isMobile={isMobileView} src='/images/yolo-mosquito-net.jpg'/>
        <MoreItemTitle isMobile={isMobileView}>YOYO mosquito nets</MoreItemTitle>
      </MoreItemContainer>
      <MoreItemContainer>
        <MoreItemImg isMobile={isMobileView} src='/images/yolo-bag.jpg'/>
        <MoreItemTitle isMobile={isMobileView}>YOYO bag</MoreItemTitle>
      </MoreItemContainer>
    </MoreItemsContainer>
    <BecomeMember/>
    <FooterContainer>
      <Footer isBlackAndWhite={true}/>
    </FooterContainer>
  </MoreDetailViewContainer>
}

const renderColorSelectionBox = (colors: string[], setColorSelected: (color: string) => void, expanded: boolean) => {
  return <ColorSelectionContainer expanded={expanded}>
    <ColorSelectionInnerContainer>
      {colors.map(color =>
        <ColorCircle onClick={() => setColorSelected(color)} color={ColorCodeMapping[color]} style={{height: '10vw', width: '10vw'}}/>
      )}
    </ColorSelectionInnerContainer>
  </ColorSelectionContainer>
}

const ProductDetailMobile = () => {
  const params = useParams()
  const productId = params.id || '0'
  const cartContext = useContext(CartItemsContext)
  const [isMobileView, setIsMobileView] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const navigate = useNavigate()
  const products = useContext(ProductsContext)
  const [selectedProduct, setSelectedProduct] = useState(products?.find(product => product._id === productId)!)
  const [selectedColor, setSelectedColor] = useState(selectedProduct.availableColors[0])
  const [expandColors, setExpandColors] = useState(false)
  const bassinet = products?.find(product => product.type === 'bassinet')
  const newBorn = products?.find(product => product.type === 'newBorn')
  const stroller = products?.find(product => product.type === 'stroller')
  const carSeat = products?.find(product => product.type === 'carSeat')

  useEffect(() => {
    setIsMobileView(isMobile)  
  }, [])

  useEffect(() => {
    setSelectedColor(selectedProduct.availableColors[0])
  }, [selectedProduct]);


  const addItemToCart = () => {
    const existingItems = cartContext?.cartItems
    const updatedItems: CartItem[] = []
    existingItems?.forEach(item => updatedItems.push(item))
    updatedItems.push(convertToCartItem(selectedProduct, selectedColor, 'black'))
    cartContext?.setCartItems(updatedItems)
  }


  const onInfoIconClick = () => {
    setShowDetails(true)
    window.scroll({top: 500, behavior: 'smooth'})
  }


  const onToggleButtonClick = (navigateTo: string, product?: Product) => {
    setSelectedProduct(product!)
    navigate(navigateTo)
  }

  const setColorSelected = (color: string) => {
    setSelectedColor(color)
    setExpandColors(false)
  }

  const expandColorSelection = () => {
    setExpandColors(true)
  }

  return <div>
  <Container>
    <ProductImgContainer>
      <div style={{width: '10vw'}}/>
      <ProductImg src={`/images/${getImagePath(selectedProduct?.imagePath, selectedColor, 'white')}`}/>
      <ColorCircle onClick={expandColorSelection} color={ColorCodeMapping[selectedColor]} style={{height: '10vw', width: '10vw'}}/>
      <ProductImgBackground color={ColorCodeMapping[selectedColor]}/>
    </ProductImgContainer>
    <ProductDetails>
      <ProductTitle>
        {selectedProduct?.title2}
        <div>
          <span style={{color: ColorCodeMapping[selectedColor], textTransform: 'uppercase', fontWeight: 600}}>{selectedColor} </span>
          {selectedProduct?.brand}
        </div>
      </ProductTitle>
      <ProductPrice>{`FROM AED ${selectedProduct.price}`}</ProductPrice>
      <ProductPriceOrDetails>or</ProductPriceOrDetails>
      <ProductPriceOrDetails>{`AED ${Math.ceil(selectedProduct.price/24)}/mo. 24mo.*`}</ProductPriceOrDetails>
      <DescriptionTitle>Complete Stroller Set Newborn Bassinet </DescriptionTitle>
      <BuyNowButton style={{marginTop: '6vw'}}>buy now</BuyNowButton>
      <MoreActionButtons>
        <BuyNowButton>
          <InfoIcon isMobile={isMobileView} onClick={() => onInfoIconClick()} src="/images/info-icon.svg"/>
        </BuyNowButton>
        <BuyNowButton onClick={addItemToCart} style={{marginTop: '1vw'}}>add to cart</BuyNowButton>
        <BuyNowButton><HeartIcon src='/images/heart-shape-black.svg'/></BuyNowButton>
      </MoreActionButtons>
    </ProductDetails>
    <ToggleButtonsContainer>
      <ToggleButton selected={bassinet?._id === productId} text='bassinet' onClick={() => onToggleButtonClick(`/products/${bassinet?._id}`, bassinet)}/>
      <ToggleButton selected={newBorn?._id === productId} text='newborn' onClick={() => onToggleButtonClick(`/products/${newBorn?._id}`, newBorn)}/>
      <ToggleButton selected={stroller?._id === productId} text='stroller' onClick={() => onToggleButtonClick(`/products/${stroller?._id}`, stroller)}/>
      <ToggleButton selected={carSeat?._id === productId} text='stroller + carseat' onClick={() => onToggleButtonClick(`/products/${carSeat?._id}`, carSeat)}/>
    </ToggleButtonsContainer>
    <BottomDiv/>
  </Container>
  {showDetails && renderMoreDetailView(isMobileView)}
  {renderColorSelectionBox(selectedProduct.availableColors, setColorSelected, expandColors)}
  </div>
}

export default ProductDetailMobile
