import React, {useEffect} from "react";
import styled from "styled-components";
import {COLORS} from "../../constants/colors";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";

const ModalContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.55);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: linear-gradient(#FFFFFF, #FEE4E7);
  height: ${isMobile ? '100%' : '75%'};
  width: ${isMobile ? '100%' : '35%'};
  padding: 2%;
  z-index: 1000;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
  margin-top: ${isMobile ? '3%': ''};
  align-items: center;
  justify-content: space-between;
`

const TitleText = styled.div`
  color: ${COLORS.lightBrown};
  font-weight: 700;
  letter-spacing: 0.1vw;
  text-align: center;
  margin-left: ${isMobile ? '2vw' : ''};
`

const CloseButton = styled.img`
  margin-right: ${isMobile ? '2vw': ''};
  height: ${isMobile ? '4vw': ''};
  cursor: pointer;
`

const HeaderLine = styled.div`
  border-bottom: 1px solid ${COLORS.lightBrown};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${isMobile ? '35%': '30%'};
  left: ${isMobile ? '7%': '18%'};
  gap: ${isMobile ? '4vw': '1vw'};
  width: ${isMobile ? '90vw': '25vw'};
  text-align: center;
`

const MessageImg = styled.img`
  height: ${isMobile ? '10vw': '3vw'};
`

const MessageTitle = styled.div`
  font-size: ${isMobile ? '5vw': '1.5vw'};
  font-weight: 700;
  color: ${COLORS.lightBrown};
`

const MessageDescription = styled.div`
  font-size: ${isMobile ? '3vw': '0.8vw'};
  font-weight: 400;
  color: ${COLORS.lightBrown};
`

const TickImg = styled.img`
  margin-top: 4vw;
  height: ${isMobile ? '12vw': '4vw'};
`

type MessageModalProps = {
  messageImage: string,
  message: string,
  description: string,
  setShowModal: (val: boolean) => void
}

const MessageModal = ({messageImage, message, description, setShowModal}: MessageModalProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [])

  const onCloseModal = () => {
    setShowModal(false)
    navigate('/')
  }

  return <ModalContainer>
    <MessageBox>
      <Title>
        <div/>
        <TitleText>MESSAGE SENT</TitleText>
        <CloseButton onClick={onCloseModal} src='/images/close-button.svg' />
      </Title>
      <HeaderLine />
      <Content>
        <MessageImg src={messageImage}/>
        <MessageTitle>{message}</MessageTitle>
        <MessageDescription>{description}</MessageDescription>
        <TickImg src='/images/tick.svg'/>
      </Content>
    </MessageBox>
  </ModalContainer>
}

export default MessageModal
