import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import styled from "styled-components";
import Footer from "../Footer/Footer";
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import { send } from 'emailjs-com';
import MessageModal from "../MessageModal/MessageModal";
import {isMobile} from "react-device-detect";
import {devices} from "../../constants/deviceSizes";
import BecomeMember from "../BecomeMember/BecomeMember";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
  background-image: linear-gradient(#FFFFFF, #FEE4E7);
`

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #FEE4E7;
`

const HeaderItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${isMobile ? '2% 5%' : '0.5% 5%'};
`

const SocialMediaImage = styled.img`
  height: ${isMobile ? '6vw' : '1.6vw'};
  cursor: pointer;
  @media only screen and ${devices.sm} {
    height: ${isMobile ? '6vw' : '3.2vw'};
  }
`

const LogoImage = styled.img`
  margin-top: 4%;
  height: 14vw;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${isMobile ? '3vw' : '1vw'};
  margin-top: ${isMobile ? '20%' : '0'};
`

const Title = styled.div`
  color: #846854;
  font-size: ${isMobile ? '5vw' : '1.5vw'};
  font-family: initial;
  font-weight: 600;
  margin-bottom: 2vw;
`

const InputField = styled.input`
  line-height: ${isMobile ? '12vw' : '1.7vw'};
  width: ${isMobile ? '87vw' : '35vw'};
  border-radius: 4px;
  border: 0.7px solid #846854;
  padding: 0.4vw;
  background-color: rgba(255,255,255,0);
`

const InputText = styled.textarea`
  width: ${isMobile ? '87vw' : '35vw'};
  height: ${isMobile ? '50vw' : '15vw'};
  border-radius: 4px;
  border: 0.7px solid #846854;
  padding: 0.4vw;
  background-color: rgba(255,255,255,0);
`

const PrivacyText = styled.div`
  width: ${isMobile ? '85vw' : '35vw'};
  font-size: ${isMobile ? '1.8vw' : '0.6vw'};
  line-height: ${isMobile ? '2vw' : '1vw'};
`

const SendButton = styled.button`
  color: #FFFFFF;
  background-color: #46332D;
  letter-spacing: 0.4vw;
  width: ${isMobile ? '87vw' : '36vw'};
  padding: ${isMobile ? '2vw' : '0.4vw'};
  cursor: pointer;
`

const OtherDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: ${isMobile ? '6vw' : '2vw'};
  width: ${isMobile ? '100vw' : '20vw'};
  gap: ${isMobile ? '2vw' : '1vw'};
`

const OtherDetailTitle = styled.div`
  color: #846854;
  font-size: ${isMobile ? '5vw': '2vw'};
`

const OtherDetailsSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const OtherDetailSubtitle = styled.div`
  color: #846854;
  font-size: ${isMobile ? '3vw' : '0.9vw'};
  text-align: center;
  line-height: ${isMobile ? '4vw' : '1.2vw'};
`

const FooterContainer = styled.div`
  width: 100%;
`

const BottomDiv = styled.div`
  height: ${isMobile ? '15vw' : '4vw'};
  background-color: #FEE4E7;
`

const GetInTouch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [state, setState] = useState({
    name: "",
    email: "",
    message: ""
  });

  const [showModal, setShowModal] = useState(false);

  const onFieldChange = (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const value = event.target.value;
    setState({...state, [event.target.id]: value});
  };

  const sendDetails = async (event: FormEvent) => {
    setShowModal(true)
    event.preventDefault()
    const templateParams = {
      to_name: 'The Baby Boutique',
      name: state.name,
      message: state.message,
      user_email: state.email
    };
    await send('service_8iiu5w6', 'template_8gjkgad', templateParams, 'MfnXkxS4jV4hMysW_')
  }

  return (
    <Container>
      <HeaderContainer>
        <HeaderItems>
          <SocialMediaIcons/>
          <SocialMediaImage src="images/contact.svg"/>
        </HeaderItems>
      </HeaderContainer>
      {!isMobile && <LogoImage src='/images/logo.svg'/>}
      <FormContainer onSubmit={sendDetails}>
        <Title>GET IN TOUCH</Title>
        <InputField id='name' required={true} onChange={onFieldChange} placeholder='Enter name'></InputField>
        <InputField id='email' required={true} onChange={onFieldChange} placeholder='Enter email'></InputField>
        <InputText id='message' required={true} onChange={onFieldChange} placeholder='Message'/>
        <PrivacyText>
          This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
        </PrivacyText>
        <SendButton type='submit'>SEND</SendButton>
      </FormContainer>
      <OtherDetailContainer>
        <OtherDetailTitle>Location</OtherDetailTitle>
        <OtherDetailsSubtitleContainer>
          <OtherDetailSubtitle>The Baby Boutique</OtherDetailSubtitle>
          <OtherDetailSubtitle>office 121, The Light Commercial Tower -</OtherDetailSubtitle>
          <OtherDetailSubtitle>Al Barsha - Al Barsha South - Dubai</OtherDetailSubtitle>
        </OtherDetailsSubtitleContainer>
      </OtherDetailContainer>
      <OtherDetailContainer>
        <OtherDetailTitle>Contact Directory</OtherDetailTitle>
        <OtherDetailsSubtitleContainer>
          <OtherDetailSubtitle>info@thebabyboutique.com</OtherDetailSubtitle>
          <OtherDetailSubtitle>+971 4 580 5455 &nbsp; +971 585 001 708</OtherDetailSubtitle>
        </OtherDetailsSubtitleContainer>
      </OtherDetailContainer>
      <OtherDetailContainer>
        <OtherDetailTitle>Customer Service</OtherDetailTitle>
        <OtherDetailsSubtitleContainer>
          <OtherDetailSubtitle>operations@thebabyboutique.com</OtherDetailSubtitle>
          <OtherDetailSubtitle>+971 4 580 5455</OtherDetailSubtitle>
        </OtherDetailsSubtitleContainer>
      </OtherDetailContainer>
      <BecomeMember/>
      <FooterContainer>
        <Footer isBlackAndWhite={true}/>
      </FooterContainer>
      {showModal && <MessageModal
        messageImage='/images/mail-box.svg'
        message='Your message has been sent'
        description='A conformation message will be sent to your email.
          Please give us 24 to 48 hours review and respond to your queries. Alternatively you can contact us via phone for any urgent matters.
          Thank you for your time and patients.'
        setShowModal={setShowModal}
      />}
      <BottomDiv/>
    </Container>
  )
}

export default GetInTouch;
