import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import Header from "../Header/Header";
import ColorStrip, {ColorCircle, ColorCircleUnselected} from "../ColorStrip/ColorStrip";
import ToggleButton from "../ToggleButton/ToggleButton";
import { InfoIcon, renderMoreDetailView } from "./ProductDetailMobile";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import {BagContainer, BagItemCount, LogoImage} from "../ProductsPage/ProductsPage";
import {CartItemsContext, ProductsContext} from "../../App";
import {CartItem, Product} from "../../types/types";
import {convertToCartItem} from "../../utils/productToCartItemUtils";
import {getImagePath} from "../../utils/imageUtils";
import {ColorCodeMapping, COLORS} from "../../constants/colors";

const Container = styled.div`
  background: linear-gradient(#FFFFFF, #FFFFFF, #FFFFFF, #FEE4E7);
  height: 100vh;
  width: 100vw;
`

const ProductDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 7%;
  padding-left: 13%;
  padding-right: 13%;
  gap: 10%;
  align-items: center;
`

const ProductImageAndColorStrip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7vw;
`

const ProductImg = styled.img`
  height: 30vw;
  width: 26vw;
  mix-blend-mode: multiply;
`

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 38%;
`

const ProductTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: #846854;
  font-size: 2vw;
  letter-spacing: 0.2vw;
`

const ProductPrice = styled.div`
  color: #846854;
  letter-spacing: 0.1vw;
  margin-top: 15%;
  margin-bottom: 5%;
  font-size: 0.7vw;
  font-weight: 500;
`

const DescriptionTitle = styled.div`
  color: #846854;
  font-weight: 600;
  margin-bottom: 3%;
  font-size: 0.7vw;
  letter-spacing: 2px;
`

const DescriptionSubtitle = styled.div`
  color: #846854;
  font-weight: 500;
  font-size: 0.65vw;
  margin-bottom: 10%;
  min-height: 8vw;
`

const BuyNowButton = styled.button<{width: string, line_height?: string}>`
  border: 0.6px #46332D solid;
  border-radius: 3px;
  color: #46332D;
  background: rgba(255,255,255,0);
  margin-top: 5%;
  line-height: ${props => props.line_height || '1vw'};
  font-size: 1.2vw;
  letter-spacing: 0.1vw;
  font-family: amstelvalalpha;
  width: ${props => props.width};
  cursor: pointer;
  
`

const MoreActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1vw;
  width: 70%;
`

const HeartIcon = styled.img`
  width: 1.3vw;
`

const Icon = styled.img`
  height: 1.7vw;
`

const ToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  padding-bottom: 2vw;
  width: 55%;
  margin: auto;
  margin-top: 5vw;
`

const FrameContainerAndImage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1vw;
  align-items: center;
`

const FrameSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vw;
`

const FrameColorAndText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1vw;
  cursor: pointer;
`

const FrameText = styled.div`
  font-size: 0.9vw;
  font-family: Amethysta;
  color: ${COLORS.lightBrown};
  letter-spacing: 0.1vw;
`

const ProductDetail = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [isMobileView, setIsMobileView] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const productId = params.id || '0'
  const cartContext = useContext(CartItemsContext)
  const products = useContext(ProductsContext)
  const [selectedProduct, setSelectedProduct] = useState(products?.find(product => product._id === productId)!)
  const [selectedColor, setSelectedColor] = useState<string>(selectedProduct.availableColors[0])
  const [selectedFrame, setSelectedFrame] = useState('black')
  const bassinet = products?.find(product => product.type === 'bassinet')
  const newBorn = products?.find(product => product.type === 'newBorn')
  const stroller = products?.find(product => product.type === 'stroller')
  const carSeat = products?.find(product => product.type === 'carSeat')

  useEffect(() => {
    setSelectedColor(selectedProduct.availableColors[0])
  }, [selectedProduct]);

  useEffect(() => {
    setIsMobileView(isMobile)  
  }, [])
  
  const onInfoIconClick = () => {
    setShowDetails(true)
    window.scroll({top: 500, behavior: 'smooth'})
  }

  const onBuyClick = () => {
    navigate(`/cart/${productId}`)
  }

  const onToggleButtonClick = (navigateTo: string, product?: Product) => {
    setSelectedProduct(product!)
    navigate(navigateTo)
  }

  const onBagIconClick = () => {
    navigate(`/cart/${productId}`)
  }

  const addItemToCart = () => {
    const existingItems = cartContext?.cartItems
    const updatedItems: CartItem[] = []
    existingItems?.forEach(item => updatedItems.push(item))
    updatedItems.push(convertToCartItem(selectedProduct, selectedColor, selectedFrame))
    cartContext?.setCartItems(updatedItems)
  }

  return <Container>
    <Header
      leftItems={[<Icon src='/images/help-icon.svg'/>, <Icon src='/images/earth-icon.svg'/>]}
      centerItem={<LogoImage src='/images/logo-small.svg'/>}
      rightItems={[
        <BagContainer onClick={onBagIconClick}>
          <Icon src='/images/shopping-bag.svg' />
          <BagItemCount>{cartContext?.cartItems?.length}</BagItemCount>
        </BagContainer>,
        <Icon src='/images/profile-icon.svg' />
      ]}
    />
    <ProductDetailContainer>
      <ProductImageAndColorStrip>
        <FrameContainerAndImage>
          <FrameSelectionContainer>
          <FrameColorAndText onClick={() => setSelectedFrame('white')}>
            {selectedFrame === 'white' ? <ColorCircle color={COLORS.white}/> : <ColorCircleUnselected color={COLORS.white}/>}
            <FrameText>White</FrameText>
          </FrameColorAndText>
          <FrameColorAndText onClick={() => setSelectedFrame('black')}>
            {selectedFrame === 'black' ? <ColorCircle color={COLORS.black}/> : <ColorCircleUnselected color={COLORS.black}/>}
            <FrameText>Black</FrameText>
          </FrameColorAndText>
        </FrameSelectionContainer>
          <ProductImg src={`/images/${getImagePath(selectedProduct?.imagePath, selectedColor, selectedFrame)}`}/>
        </FrameContainerAndImage>
        <ColorStrip colors={selectedProduct?.availableColors} selectedColor={selectedColor} setSelectedColor={setSelectedColor}/>
      </ProductImageAndColorStrip>
      <ProductDetails>
        <ProductTitle>
          {selectedProduct?.title2}
          <div>
            <span style={{color: ColorCodeMapping[selectedColor], textTransform: 'uppercase', fontWeight: 600}}>{selectedColor} </span>
            {selectedProduct?.brand}
          </div>
        </ProductTitle>
        <ProductPrice>{`FROM AED ${selectedProduct.price} or AED ${Math.ceil(selectedProduct.price/24)}/mo. 24mo.*`}</ProductPrice>
        <DescriptionTitle>{selectedProduct.detail.title}</DescriptionTitle>
        <DescriptionSubtitle>
          {selectedProduct.detail.subTitles.map((subTitle: string) => <div>{subTitle}<br/><br/></div>)}
        </DescriptionSubtitle>
        <BuyNowButton line_height='2vw' width='70%' onClick={onBuyClick}>buy now</BuyNowButton>
        <MoreActionButtons>
          <BuyNowButton width='30%'>
            <InfoIcon isMobile={isMobileView} onClick={() => onInfoIconClick()} src="/images/info-icon.svg"/>
          </BuyNowButton>
          <BuyNowButton onClick={addItemToCart} width='100%'>add to cart</BuyNowButton>
          <BuyNowButton width='30%'><HeartIcon src='/images/heart-shape-black.svg'/></BuyNowButton>
        </MoreActionButtons>
      </ProductDetails>
    </ProductDetailContainer>
    {!(['frame', 'connector'].includes(selectedProduct.type)) &&
    <ToggleButtonsContainer>
      <ToggleButton selected={bassinet?._id === productId} text='bassinet' onClick={() => onToggleButtonClick(`/products/${bassinet?._id}`, bassinet)}/>
      <ToggleButton selected={newBorn?._id === productId} text='newborn' onClick={() => onToggleButtonClick(`/products/${newBorn?._id}`, newBorn)}/>
      <ToggleButton selected={stroller?._id === productId} text='stroller' onClick={() => onToggleButtonClick(`/products/${stroller?._id}`, stroller)}/>
      <ToggleButton selected={carSeat?._id === productId} text='stroller + carseat' onClick={() => onToggleButtonClick(`/products/${carSeat?._id}`, carSeat)}/>
    </ToggleButtonsContainer>
    }
    {showDetails && renderMoreDetailView(isMobileView)}
  </Container>
}

export default ProductDetail
