import React from "react"
import { isMobile } from "react-device-detect"
import { styled } from "styled-components"
import { COLORS } from "../../constants/colors"

const BecomeMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vw;
`

const JoinUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
  margin-top: ${isMobile ? '6vw' : '2vw'};
`

const BecomeMemberText = styled.div`
  font-size: ${isMobile ? '7vw' : '2vw'};
  color: ${COLORS.lightBrown};
  text-align: center;
  font-weight: 800;
  letter-spacing: 0.7vw;
  width: ${isMobile ? '45vw' : '20vw'};
`

const JoinUsSubHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isMobile ? '87vw' : '20vw'};
  margin-top: ${isMobile ? '5vw' : '1vw'};
`

const JoinUsSubHeading = styled.div`
  font-size: ${isMobile ? '3vw' : '0.7vw'};
  color: ${COLORS.lightBrown};
  text-align: center;
  font-weight: 400;
  letter-spacing: ${isMobile ? '0.2vw' : '0'};
  line-height: ${isMobile ? '4vw' : '0.9vw'};
`

const EmailInput = styled.input`
  border-bottom: 1px solid #46332D;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: ${isMobile ? '87vw' : '25vw'};
  background-color: rgba(255,255,255,0);
  margin-top: ${isMobile ? '10%' : '5%'};
  
  &:focus {
    outline: none;
  }
`

const JoinButton = styled.button`
  position: relative;
  top: ${isMobile ? '-6.2vw' : '-1.2vw'};
  left: ${isMobile ? '40vw' : '11.5vw'};
  border: 0;
  background-color: rgba(255,255,255,0);
  cursor: pointer;
  font-size: ${isMobile ? '4vw' : '0.8vw'};
`

const BecomeMember = () => {
  return <BecomeMemberContainer>
      <JoinUsContainer>
        <BecomeMemberText>BECOME A MEMBER</BecomeMemberText>
        <JoinUsSubHeadingContainer>
            <JoinUsSubHeading>Subscribe to the members club for exclusive offers & new product announcements special offers, VIP early access</JoinUsSubHeading>
        </JoinUsSubHeadingContainer>
      </JoinUsContainer>
      <EmailInput placeholder='Email'></EmailInput>
      <JoinButton>Join</JoinButton>
    </BecomeMemberContainer>
}

export default BecomeMember